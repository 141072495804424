import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Tag, Button, message } from 'antd';
import './index.scss';
import IconFont from '@/components/IconFont';
import SearchHeader from '@/components/SearchHeader';
import DeviceTable from './components/DeviceTable';
import RightTab from './components/RightTab';
import RightTabMain from './components/RightTabMain';

import { getDeviceManage, getDevicePlace, getDeviceDetail, getDevicePlaceCmd1, getDeviceControlOption, getSensorData, startToConnect, startToSearch} from '@/server/device';
import _ from 'lodash';

const searDataMode = [
	{
		label: '位置：',
		name: 'location',
		type: 'selelct',
		required: false,
		message: '请选择位置！',
		placeholder: '请输入位置',
		defaultValue: 0,
		listData: [
			{
				name: '全部',
				value: 0
			}
		]
	},
	{
		label: '中控状态：',
		name: 'status',
		type: 'selelct',
		defaultValue: '3',
		required: false,
		message: '请选择中控状态',
		listData: [
			{ value: '3', name: '全部' },
			{ value: '0', name: '开机' },
			{ value: '1', name: '离线' }
		]
	},
	{ type: 'input', name: 'text', width: '270', defaultValue: '', placeholder: '输入位置、IP地址或序列号进行搜索' },
	{ type: 'button', icon: 'icon-sousuo', color: '#4164F0', defaultValue: '搜索', submit: true }
];

const List = () => {
	const [searchData, setSearchData] = useState(searDataMode);
	const [showCheckBox, setShowCheckBox] = useState(false);
	const [activeTab, setActiveTab] = useState(1);
	const [showRightSider, setShowRightSider] = useState(false);
	const [pageNum, setPageNum] = useState(1);
	const [data, setData] = useState({
		lock_number: 0,
		off_number: 0,
		on_number: 0,
		online_number: 0,
		total: 0,
		list: []
	});
	const [searchParams, setSearchParams] = useState({
		place: '',
		onlineState: '3',
		keyword: ''
	});
	const [searchTimeStamp, setsearchTimeStamp] = useState('');
	const [deviceDetail, setDeviceDetail] = useState({});
	const [placeList, setPlaceList] = useState([]);
	const [controlOptions, setControlOptions] = useState([]);//批量控制项列表
	const [sensorData, setSensorData] = useState({});
	const toggleEdit = () => {
		setShowCheckBox(!showCheckBox);
	};
	const changeRightTab = tabIndex => {
		setActiveTab(tabIndex);
	};
	const autoConnect = async () => {
		const res = await startToSearch();
		if (_.get(res, 'result.code') == 0) {			
			message.success('操作成功');
		}
	}

	const deviceSensorData = async (id)=>{				
		const {sensor_data} = await getSensorData({ device: {
			id: id
		}});
		setSensorData(sensor_data)
	}
	/** 点击 搜索 */
	const operation = params => {
		const place = params.data.location;
		const onlineState = params.data.status;
		const keyword = params.data.text || '';
		setSearchParams({ place, onlineState, keyword });
		/** 防止重复触发查询列表 确保只触发1次 */
		if (pageNum === 1) {
			setsearchTimeStamp(new Date().getTime());
		} else {
			setPageNum(1);
		}
	};
	const toggleShowRightSider = () => {
		setShowRightSider(!showRightSider);
	};

	/** 查询设备位置 */
	const fetchDevicePlace = async () => {
		const res = await getDevicePlace();
		if (_.get(res, 'result.code') == 0) {
			const list = _.get(res, 'sort_list', []);
			let placeSelectOption = [];
			_.forEach(list, item => {
				placeSelectOption.push({
					name: item.name,
					value: item.id
				});
			});
			searDataMode[0].defaultValue = _.get(list, '[0].id');
			searDataMode[0].listData = placeSelectOption;
			setSearchData([...searDataMode]);
		}
	};

	/** 查询设备列表 */
	const fetchDeviceList = async () => {
		const res = await getDeviceManage({
			page: {
				size: 10,
				page: pageNum
			},
			device_search: {
				keyword: searchParams.keyword,
				place: searchParams.place,
				online_state: Number(searchParams.onlineState)
			},
			device_order: ''
		});
		if (_.get(res, 'result.code') === 0) {
			const data = _.get(res, 'device_info_list', {});
			setData(data);
		}
	};

	/** 查询单个设备详情 */
	const fetchDeviceDetail = async id => {
		setShowRightSider(true);
		deviceSensorData(id);
		const res = await getDeviceDetail({
			device: {
				id
			}
		});
		if (_.get(res, 'result.code') == 0) {
			let device_control_info = _.get(res, 'device_control_info', {});
			//增加设备在线离线状态
			const {device_property_list} = data;
			for(let i = 0; i < device_property_list.length; i++){
				if(device_property_list[i].id == id){
					device_control_info.on_off_state = device_property_list[i].device_iot_state?.device_state;
					break;
				}
			}
			setDeviceDetail(device_control_info);
		}
	};
	/** 查询目标接收位置列表 */
	const fetchSettingPlaceList = async () => {
		const res = await getDevicePlaceCmd1();
		if (_.get(res, 'result.code') == 0) {
			const placelist = _.get(res, 'placelist', []);
			if(placelist){
				let place = [{
					id: 0,
					level: 2,
					name: "全部"}];
				place.push(...placelist);
				setPlaceList(place);
			}
		}
	};
	/** 触发设备列表接口刷新 用于表格中 复制/替换设备后的回调 */
	const refreshList = () => {
		if (pageNum === 1) {
			setsearchTimeStamp(new Date().getTime());
		} else {
			setPageNum(1);
		}
	};
	/** 获取批量控制选项 */
	const getControlOption = async () => {
		const res = await getDeviceControlOption();
		const batchList = _.get(res, "batch_control_item_list", []);
		const controlOptions = _.map(batchList, item =>{
			return {
				...item,
				value: ''
			}
		})
		setControlOptions(controlOptions)
	};
	useEffect(() => {
		fetchDevicePlace();
		fetchSettingPlaceList();
		getControlOption();
	}, []);
	useEffect(() => {
		fetchDeviceList();
	}, [pageNum, searchTimeStamp]);
	const onPageSizeChange = page => {
		setPageNum(page);
	};
	const total = _.get(data, 'total', 0);
	const list = _.get(data, 'device_property_list', []);
	return (
		<section className="device-page full-content">
			<section className={`device-left ${showRightSider && 'showRightSider'}`}>
				<section className="custom-layout">
					<header className="custom-layout-header">
						<SearchHeader data={searchData} operation={operation} />
					</header>
					<section className="left-main custom-layout-content">
						<div className="status-control">
							<Tag className="status-button status-online" color="blue">
								开机:{_.get(data, 'on_number', 0)}/{total}
							</Tag>
							<Tag className="status-button status-turnoff" color="magenta">
								离线:{total - _.get(data, 'on_number', 0)}/{total}
							</Tag>
							<Button className="button-edit" type="primary" onClick={toggleEdit}>
								{showCheckBox ? '取消' : '编辑'}
							</Button>
							<Button className="button-connect" type="primary" onClick={autoConnect}>
								{'添加模块'}
							</Button>
						</div>
						<DeviceTable refreshList={refreshList} controlOptions={controlOptions} placeList={placeList} data={list} total={total} current={pageNum} showCheckBox={showCheckBox} onCancel={toggleEdit} onPageSizeChange={onPageSizeChange} onItemClick={fetchDeviceDetail} />
					</section>
				</section>
				<div className={`toggleVisibleRight ${showRightSider ? '' : 'active'}`} onClick={toggleShowRightSider}>
					<IconFont type="icon-bofangsanjiaoxing" className="icon-arr" />
				</div>
			</section>
			{showRightSider && (
				<section className="device-right">
					<section className="custom-layout">
						<header className="custom-layout-header">
							<RightTab onClickHandle={changeRightTab} activeTab={activeTab} />
						</header>
						<section className="custom-layout-content">
							<RightTabMain activeTab={activeTab} data={deviceDetail} fetchDeviceDetail={fetchDeviceDetail} refreshList={refreshList} sensorData={sensorData}/>
						</section>
					</section>
				</section>
			)}
		</section>
	);
};

export default List;
