import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Input, Button, message} from 'antd';
import IconFont from '@/components/IconFont'
import { getPasswordChangeShortmessage, userUpdatePassword} from '@/server/login'
import './index.scss';
import md5 from 'blueimp-md5'
import { values } from 'lodash';

const FormItem = Form.Item;

class Stepscontarner extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      liked: true,
      count: 60,
      phone: '',
      password: '',
      confirmPassword: '',
      step: 1
    }
  }
  componentDidMount() {
  
  }
  // 倒计时
	countDown = () => {
    const {count} = this.state;
    if (count === 1) {
      this.setState({
        count: 60,
        liked: true,
      });
    } else {
      this.setState({
        count: count - 1,
        liked: false,
      });
      setTimeout(this.countDown, 1000)
    }
	}

  // 发送验证码
	handleClick = async () => {
    const {liked} = this.state;
    if (!liked) {
      return;
    }
    try{
      await this.formRef.current.validateFields(['phone']).then((values) => {
        if(values && values.phone){
          getPasswordChangeShortmessage({
            "phone_login": {
              "phone": values.phone
          }
          });
          this.countDown();
        }
      })
    }
    catch(error){
      message.error('手机号填写不正确');
    }
  };
  
  // 确定
  submit = async(e) => {
    e.preventDefault();
    this.formRef.current.validateFields().then(async(values) => {      
      if(values.password != values.confirmPassword){
        message.error('两次输入的密码不一致!');
        return;
      }
      const data = await userUpdatePassword({
        "phone_login": {
          "phone": values.phone,
          "code": values.phoneCode,
          "password": md5(values.password)
      }
      });
      if (data.result.code === 0) {
        message.success('操作成功');
        this.setState({
          step: 2
        });
      }
    }).catch((errorInfo ) => {
			console.log(errorInfo );
		})
  }

	render() {
		return (
			<div className="containerStep">
        {/* 第一步 begin */}
        {this.state.step == 1 && <div className="stepnoe">
          <div className="step-title">
            <div className="step-text-main">手机验证码验证</div>
            <div className="step-text-vice">为确保是本人进行操作，请完成以下验证</div>
          </div>
          <div className="step-z">
            <div className="step-from">
              <Form ref={this.formRef} labelAlign="left" wrapperCol={{ span: 18, offset: 1 }} labelCol={{ span: 5 }}>
              <FormItem 
                  label="手机号码:"
									name="phone"
                  rules={[{ required: true, pattern:/^1[0-9]{10}$/, message:'请输入正确的手机号'}]}
								>
                <Input ></Input>
              </FormItem>
              <FormItem 
                  label="密码:"
									name="password"
                  rules={[{ required: true, message: '请填写密码!' }]}
								>
                <Input type="password" placeholder="请输入密码"></Input>
              </FormItem>
              <FormItem 
                  label="确认密码:"
									name="confirmPassword"
                  rules={[{ required: true, message: '请填写确认密码!' }]}
								>
                <Input type="password" placeholder="请输入确认密码"></Input>
              </FormItem>
              <FormItem 
                  label="验证码:"
                  name="phoneCode"
                  rules={[{ required: true, message: '请输入验证码!' }]}
								>
                <div className="phoneCode">
                <div className="phoneInput">
                  <Input placeholder="请输入验证码"/>
                </div>
                <div className="code-click">
										<Button type="primary" disabled={!this.state.liked} onClick={()=>{console.log(this);this.handleClick()}}>
                      {
                        this.state.liked? '获取短信验证码': `${this.state.count} 秒后重发`
                      }
									</Button>
								</div>
                </div>
              </FormItem>
              <FormItem>
                <div className="submit">
									<Button type="primary"  htmlType="submit" onClick={this.submit}>
										确认
									</Button>
                </div>
							</FormItem>
              </Form>
            </div>
          </div>
          <div className="message">
            <div className="message-h1">没收到短信验证码？</div>
            <div className="message-con">
              <span>1、网络通讯异常可能会造成短信丢失，请重新获取或稍后再试。</span>
              <span>2、请核实手机是否已欠费停机，或者屏蔽了系统短信。</span>
              <span>3、如果手机已丢失或停用，请选择其他验证方式。</span>
              <span>4、您也可以尝试将SIM卡移动到另一部手机，然后重试。</span>
            </div>
          </div>
        </div>
      }
        {
          this.state.step === 2 ? (<div className="stepthree">
           <div className="suss-icon">
            <IconFont type='icon-chenggong'/>
           </div>
           <div className="suss-text">
             密码修改成功，请点击<i onClick={this.props.security}>重新登录</i>
             </div>
         </div>) : ''
        }
        {/* 第三步 end */}
			</div>
		);
	}
}

export default withRouter(Stepscontarner)
