import React, { useEffect,Component } from 'react';
import * as echarts from 'echarts';

class Gauge extends Component{

	constructor(props){
		const { id, color, value, name } = props;
		super(props);
		this.state = {
			id: id,
			color: color,
			value: value,
			name: name
		}
	}
	
	componentDidMount() {
		this.renderChart();
	}
	
	renderChart = () => {
		var chartDom = document.getElementById(this.state.id);
		var myChart = echarts.init(chartDom);
		var option;
		let color = this.state.color;
		option = {
			series: [
				{
					type: 'gauge',
					startAngle: 180,
					endAngle: -180,
					radius: '80%',
					color,
					pointer: {
						show: false
					},
					progress: {
						show: true,
						overlap: false,
						roundCap: false,
						clip: false,
						itemStyle: {
							borderWidth: 1
						}
					},
					axisLine: {
						show: false,
						lineStyle: {
							width: 10
						}
					},
					splitLine: {
						show: false,
						distance: 0,
						length: 10
					},
					axisTick: {
						show: false
					},
					axisLabel: {
						show: false,
						distance: 50
					},
					data: [
						{
							value: this.state.value,
							name: this.state.name,
							title: {
								show: false,
								offsetCenter: ['0%', '-30%']
							},
							detail: {
								offsetCenter: ['0%', '0%']
							},
							splitLine: {
								show: false
							}
						}
					],
					detail: {
						fontSize: 14,
						color: this.state.color,
						formatter: '{value}%'
					}
				}
			]
		};

		myChart.setOption(option, true);
		option && myChart.setOption(option);
	};
	
	render(){
		return (
			<div className="gauge-box">
				<div className="gauge" id={this.state.id}></div>
			</div>
		);
	}

};

export default Gauge;
