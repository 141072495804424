import React, { Component } from 'react';
import { Select, Table, Tabs } from 'antd';
import { getLogList } from '@/server/system/network'
import '../index.scss'

const { TabPane } = Tabs;

class LogQuery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
      ],
      columns: [
        {
          title: '序号',
          align: 'center',
          ellipsis: true,
          key: 'index',
          dataIndex: 'index',
        },
        {
          title: '用户名称',
          align: 'center',
          key: 'user_name',
          dataIndex: 'user_name',
        },
        {
          title: '登录时间',
          align: 'center',
          key: 'time',
          dataIndex: 'time',
        },
        {
          title: '登录Ip',
          align: 'center',
          key: 'part1',
          dataIndex: 'part1',
        },
        {
          title: '登录方式',
          align: 'center',
          key: 'part2',
          dataIndex: 'part2',
        }        
      ],
      columns2: [
        {
          title: '序号',
          align: 'center',
          ellipsis: true,
          key: 'index',
          dataIndex: 'index',
        },
        {
          title: '操作员',
          align: 'center',
          key: 'user_name',
          dataIndex: 'user_name',
        },
        {
          title: '操作时间',
          align: 'center',
          key: 'time',
          dataIndex: 'time',
        },
        {
          title: '操作',
          align: 'center',
          key: 'part1',
          dataIndex: 'part1',
        },
        {
          title: '设备名',
          align: 'center',
          key: 'part2',
          dataIndex: 'part2',
        }        
      ],
      columns3: [
        {
          title: '序号',
          align: 'center',
          ellipsis: true,
          key: 'index',
          dataIndex: 'index',
        },
        {
          title: '操作员',
          align: 'center',
          key: 'user_name',
          dataIndex: 'user_name',
        },
        {
          title: '操作时间',
          align: 'center',
          key: 'time',
          dataIndex: 'time',
        },
        {
          title: '操作类型',
          align: 'center',
          key: 'part1',
          dataIndex: 'part1',
        },
        {
          title: '任务名',
          align: 'center',
          key: 'part2',
          dataIndex: 'part2',
        }
      ],
        columns4: [
          {
            title: '序号',
            align: 'center',
            ellipsis: true,
            key: 'index',
            dataIndex: 'index',
          },
          {
            title: '操作员',
            align: 'center',
            key: 'user_name',
            dataIndex: 'user_name',
          },
          {
            title: '操作时间',
            align: 'center',
            key: 'time',
            dataIndex: 'time',
          },
          {
            title: '操作',
            align: 'center',
            key: 'part1',
            dataIndex: 'part1',
          },
          {
            title: '详情',
            align: 'center',
            key: 'part2',
            dataIndex: 'part2',
          }       
      ],
      page: 1,
      size: 10,
      time: 1,
      type: 1,
      total: 0,
    }
  }

  componentDidMount() {
    this.getLogListFun()
  }

  logChange = (e) => {
    this.setState({
      type: e,
      page: 1
    }, () => {
      this.getLogListFun()
    })
  }

  getLogListFun = async() => {
    const {log_list, total} = await getLogList({
      "page": {
          "page": this.state.page,
          "size": 10
      },
      "log_info": {
          "type":Number(this.state.type),
          "time": Number(this.state.time)
      }
    })

    this.setState({
      data: log_list,
      total: total
    });
  }

  logTimeFun = (e) => {
    this.setState({
      time: e
    }, () => {
      this.getLogListFun()
    })
  }
   // 分页切换
   onChangeTable = (e) => {
    this.setState({
      page: e
    }, () => {
      this.getLogListFun()
    })
  }

  render() {
    return (
      <div className="account">
        <div className="log-query-main">
          <div className="log-query-select">
          <Select placeholder='请选择查询时间范围' defaultValue='1' onChange={this.logTimeFun} style={{ width: '206px' }}>
              <Select.Option value='1'>最近一天</Select.Option>
              <Select.Option value='2'>最近一周</Select.Option>
              <Select.Option value='3'>最近一个月</Select.Option>
              <Select.Option value='4'>最近三个月</Select.Option>
          </Select>
          </div>
          <Tabs defaultActiveKey="1" onChange={this.logChange} key="1">
          <TabPane tab="登录日志" key="1">
          <Table
          pagination={{ defaultPageSize: 10, hideOnSinglePage: true,  total: this.state.total, onChange: this.onChangeTable }}
          rowSelection={this.state.rowSelection}
          columns={this.state.columns}
          dataSource={this.state.data}
        />
          </TabPane>
          <TabPane tab="设备日志" key="2">
          <Table
          pagination={{ defaultPageSize: 10, hideOnSinglePage: true,  total: this.state.total, onChange: this.onChangeTable }}
          rowSelection={this.state.rowSelection}
          columns={this.state.columns2}
          dataSource={this.state.data}
        />
          </TabPane>
          <TabPane tab="任务日志" key="3">
          <Table
          pagination={{ defaultPageSize: 10, hideOnSinglePage: true,  total: this.state.total, onChange: this.onChangeTable }}
          rowSelection={this.state.rowSelection}
          columns={this.state.columns3}
          dataSource={this.state.data}
        />
          </TabPane>
          <TabPane tab="系统日志" key="4">
          <Table
          pagination={{ defaultPageSize: 10, hideOnSinglePage: true,  total: this.state.total, onChange: this.onChangeTable }}
          rowSelection={this.state.rowSelection}
          columns={this.state.columns4}
          dataSource={this.state.data}
        />
          </TabPane>
        </Tabs>
        </div>
      </div>
    );
  }
}

export default LogQuery;
