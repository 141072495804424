import React from 'react';
import {Table} from 'antd';

const BottomRightTable = (props={}) => {
	const {data} = props;
	const tableData = [];
	let total = data.number1 + data.number2 + data.number3;
	tableData.push({
		key: 0,
		status: data.state1,
		number: data.number1,
		radio: (data.number1 * 100 / total).toFixed(2) + '%'
	});
	tableData.push({
		key: 1,
		status: data.state2,
		number: data.number2,
		radio: (data.number2 * 100 / total).toFixed(2) + '%'
	});
	tableData.push({
		key: 2,
		status: data.state3,
		number: data.number3,
		radio: (data.number3 * 100 / total).toFixed(2) + '%'
	});
  const tableHeader = [
		{
			title: '状态',
			dataIndex: 'status',
      key: 'status',
    },  
		{
			title: '数量',
			dataIndex: 'number',
			key: 'number',
    },
		{
			title: '占比',
			dataIndex: 'radio',
			key: 'radio',
		},            
  ]  
  return (
    <section className="bottom-right-table">
      <header className="table-title">{data.name}总数{total}台</header>
      <Table dataSource={tableData} columns={tableHeader} pagination={false}/>
    </section>
  )
}

export default BottomRightTable;