import React from "react";
import { Table } from "antd";

const ElectricTable = (props) => {
  const {data} = props;
    const tableData = data;
    const tableHeader = [
      {
        title: '教室',
        dataIndex: 'place',
        key: 'place',
        align: 'center'      
      },
      {
        title: '日期',
        dataIndex: 'date',
        key: 'date',
        align: 'center'      
      },
      {
        title: '能耗',
        dataIndex: 'kwhs',
        key: 'kwhs',
        align: 'center'      
      }                 
    ];   
  return (
    <section className="electric-table-wrapper">
      <header className="electric-table-title">用电详情统计(kW·h)</header>
      <Table className="electric-table" dataSource={tableData} columns={tableHeader} pagination={{ defaultPageSize: 10 }}></Table>
    </section> 
  )
}

export default ElectricTable;