import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as echarts from 'echarts';
import _ from 'lodash';

let myChart = null;
let refreshData = null;
const BarChartMonth = props => {
	const isCollapsed = useSelector(state => state.collapse.isCollapsed);
	const {data, refresh} = props;
	let xAxisData = [];
	let yAxisData = [];
	refreshData = data;
	for(let i = 0; i < data.length; i++){
		const item = data[i];
		xAxisData.push(String(item.month));
		yAxisData.push(Number(item.kwhs));
	}
	
	const renderChart = () => {
		myChart && myChart.clear();
		myChart = null;
		var chartDom = document.getElementById('barchartMonth');
		myChart = echarts.init(
			chartDom,
			{},
			{
				devicePixelRatio: 1,
				width: chartDom.clientWidth
			}
		);
		var option = {
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					// 坐标轴指示器，坐标轴触发有效
					type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
				}
			}, 
			legend: {
				top: 'bottom'
			},
			grid: {
				top: 20,
				bottom: 0,
				left: 0,
				right: 20,
				containLabel: true,
				borderWidth: 0
			},
			toolbox: {
				feature: {}
			},
			xAxis: {
			    type: 'category',
				data: xAxisData,
				axisTick: {
					alignWithLabel: true
				}
			},
			yAxis: {
				type: 'value',
				axisTick: false,
				axisLine: {
					show: false
				}
			},
			series: [{
				data: yAxisData,
				type: 'bar',
				barWidth: 25,
				showBackground: true,
				backgroundStyle: {
					color: 'rgba(180, 180, 180, 0.2)'
				}
			}]
		};

		option && myChart.setOption(option);		
		myChart.on('click', (params)=>{
			let seriesIndex = params.seriesIndex;
			if(refreshData.length > seriesIndex){
				refresh && refresh(refreshData[seriesIndex]);
			}
		});
	};
	if(myChart){
		var opt = myChart.getOption();
		opt.xAxis[0].data = xAxisData;
		opt.series[0].data = yAxisData; 	
		myChart.setOption(opt);
	}
	
	
	const watchWindowSize = () => {
		_.delay(() => {
			myChart && myChart.resize({
				width:  document.getElementById('BarChartMonth') && document.getElementById('BarChartMonth').clientWidth
			});
		}, 300);
	};

	useEffect(() => {
		renderChart();
	}, []);

	useEffect(() => {
		window.addEventListener('resize', watchWindowSize, false);
		return () => window.removeEventListener('size', watchWindowSize, false);
	});
	useEffect(() => {
		watchWindowSize();
	}, [isCollapsed]);
	return (
		<div className="line-chart-container">
			<div id="barchartMonth" className="barchartMonth">
			</div>
		</div>
	);
};

export default BarChartMonth;
