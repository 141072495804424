import { get, post } from '@/axios';

/** 设备列表获取 */
export const getDeviceManage = (query = {}) => {
	return post(`/app/device-manage?cmd=1`, query);
};

/** 设备详情 */
export const getDeviceDetail = (query = {}) => {
	return post(`/app/device-manage?cmd=10`, query);
};

/** 单个设备控制 */
export const setDeviceControl = (query = {}) => {
	return post(`/app/device-manage?cmd=2`, query);
};

/** 设备筛选 位置获取 */
export const getDevicePlace = (query = {}) => {
	return post(`/app/place?cmd=15`, query);
};

/** 接收成员--位置获取(场景/批量控制) */
export const getDevicePlaceCmd1 = (query = {}) => {
	return post(`/app/place?cmd=1`, query);
};

/** 设备设置-详情 */
export const getDeviceSettingDetail = (query = {}) => {
	return post(`/app/device-setting?cmd=20`, query);
};

/** 设备设置-复制 */
export const deviceSettingCopy = (query = {}) => {
	return post(`/app/device-setting?cmd=11`, query);
};

/** 设备设置-替换 */
export const deviceSettingReplce = (query = {}) => {
	return post(`/app/device-setting?cmd=12`, query);
};

/** 设备批量控制项获取 */
export const getDeviceControlOption = (query = {}) => {
	return post(`/app/device-manage?cmd=4`, query);
};

/** 设备删除(单个或批量删除) */
export const postDeviceDel = (query = {}) => {
	return post(`/app/device-manage?cmd=18`, query);
};

/** 设备批量控制 */
export const postDeviceBatchControl = (query = {}) => {
	return post(`/app/device-manage?cmd=8`, query);
};

/** 设备设置 网络设置信息获取 */
export const getDeviceSettingNetworkInfo = (query = {}) => {
	return post(`/app/device-setting?cmd=18`, query);
};
/** 设备设置 网络设置信息获取 */
export const postDeviceSettingNetworkInfo = (query = {}) => {
	return post(`/app/device-setting?cmd=19`, query);
};

/** 设备设置 物联设备列表获取 */
export const getIotList = (query = {}) => {
	return post(`/app/device-setting?cmd=13`, query);
};

/** 设备设置 物联设备参数获取 */
export const getIotListOptions = (query = {}) => {
	return post(`/app/device-setting?cmd=15`, query);
};

/** 设备设置 物联设备添加 */
export const postAddIot = (query = {}) => {
	return post(`/app/device-setting?cmd=16`, query);
};
/** 设备设置 物联设备删除 */
export const postDelIot = (query = {}) => {
	return post(`/app/device-setting?cmd=14`, query);
};
/** 设备设置 物联设备修改 */
export const postEditIot = (query = {}) => {
	return post(`/app/device-setting?cmd=17`, query);
};

/** 设备设置 物联模块列表获取 */
export const getDeviceModuleList = (query = {}, showloading = true) => {
	return post(`/app/device-setting?cmd=5`, query, true, showloading);
};

/** 设备设置 物联模块配置信息获取 */
export const getDeviceModuleDetail = (query = {}) => {
	return post(`/app/device-setting?cmd=9`, query);
};

/** 设备设置 物联模块修改名称 */
export const postDeviceRename = (query = {}) => {
	return post(`/app/device-setting?cmd=8`, query);
};

/** 设备设置 物联模块删除 */
export const postDeviceModuleDel = (query = {}) => {
	return post(`/app/device-setting?cmd=7`, query);
};

/** 设备设置 物联模块添加 */
export const postDeviceModuleAdd = (query = {}) => {
	return post(`/app/device-setting?cmd=6`, query);
};

/** 设备设置 物联模块串口信息设置 */
export const postDeviceModulePort = (query = {}) => {
	return post(`/app/device-setting?cmd=10`, query);
};
/** 高级设置 获取 */
export const getDeviceAdvancedSetting = (query = {}) => {
	return post(`/app/device-setting?cmd=4`, query);
};
/** 高级设置 设置 */
export const setDeviceAdvancedSetting = (query = {}) => {
	return post(`/app/device-setting?cmd=3`, query);
};
/** 传感器数据 */
export const getSensorData = (query = {}) => {
	return post(`/app/sensor?cmd=7`, query);
};
/** 开始、停止搜索模块 */
export const startSearchModule = (query = {}) => {
	return post(`/app/device-setting?cmd=23`, query);
};
/** 设备设置 获取未连接的设备列表 */
export const getDeviceUnlinkModuleList = (query = {}, showloading = true) => {
	return post(`/app/device-setting?cmd=24`, query, true, showloading);
};
/** 连接模块 */
export const connectDevice = (query = {}, showloading = true) => {
	return post(`/app/device-setting?cmd=25`, query, true, showloading);
};
/** 开始搜索 */
export const startToSearch = (query = {}) => {
	return post(`/app/device-setting?cmd=26`, query);
};
/** 开始连接 */
export const startToConnect = (query = {}) => {
	return post(`/app/device-setting?cmd=27`, query);
};