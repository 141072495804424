import React, { useState,useEffect } from 'react';
import IconFont from '@/components/IconFont';
import { Table, Form, Input, Button } from 'antd';
import { getFunctionCodeList } from '@/server/function_code';
import _ from 'lodash';
import { number } from 'prop-types';

const category = [
	{
		index: 0,
		name: '投影仪',
		device_type: 3
	},
	{
		index: 1,
		name: '大屏一体机',
		device_type: 6
	},
	{
		index: 2,
		name: '空调',
		device_type: 2
	},
	{
		index: 3,
		name: '自定义设备',
		device_type: 10
	}
];


const ControlCode = (props = {}) => {
	const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
	const [pageNum, setPageNum] = useState(1);
	const [controlCodeList, setControlCodeList] = useState([]);
	const [deviceFunctionType, setDeviceFunctionType] = useState([]);
	const [currentFunctionCodeIndex, setCurrentFunctionCodeIndex] = useState(0);	
	const [functionCodeList] = Form.useForm();
	const onChange = ({ index }) => {
		setCurrentCategoryIndex(index);
		fetchFunctionCodeList(index)
	};

	useEffect(() => {
		fetchFunctionCodeList(currentCategoryIndex)
	}, []);
	
	const fetchFunctionCodeList = async (index)=>{
		if(category.length > index){
			let info = category[index];
			const res = await getFunctionCodeList({ 
				page: {
					size: 1000,
					page: pageNum
				},
				device_type: info.device_type
			});
			if (_.get(res, 'result.code') == 0) {
				const device_function_type = _.get(res, 'device_function_type', []);
				setDeviceFunctionType(device_function_type);
				//控制码需要分成n个部分
				const function_code_list = _.get(res, 'function_code_list', []);
				let controlCodeList = [];
				setCurrentFunctionCodeIndex(0);
				for(let index = 0; index < function_code_list.length; index++){
					let find = false;
					const item = function_code_list[index];
					for(let i = 0; i < controlCodeList.length; i++){
						if(controlCodeList[i].id == item.id){
							find = true;
							controlCodeList[i].functionCodeList.push(item);
						}
					}
					if(!find){
						controlCodeList.push(
							{
								id: item.id,
								brand_name: item.brand_name,
								baudrate: item.baudrate,
								functionCodeList: [item]
							}
						);
					}
				};
				console.log(controlCodeList);
				setControlCodeList(controlCodeList);
			}
		}else{
			console.error('程序内部错误');
		}		
	};

	const tableHeader = [
		{
			title: '名称',
			dataIndex: 'brand_name',
			key: 'name',
			align: 'center'
		},
		{
			title: '操作',
			align: 'center',
			render: () => {
				return (
					<div className="cell-action-btns">
						<IconFont type="icon-delete" className="icon icon-del" />
					</div>
				);
			}
		}
	];

	return (
		<div className="control-code-wrapper">
			<header className="page-title">设备设置</header>
			<section className="control-code-main">
				<section className="control-code-category">
					{category.map(item => {
						return (
							<div className={`item ${item.index === currentCategoryIndex && 'active'}`} key={item.index} onClick={() => onChange(item)}>
								{item.name}
							</div>
						);
					})}
				</section>
				<div className="control-container">
					<section className="control-left">
						<div className="left-header">
							<IconFont type="icon-add1" name="添加" className="icon-btn" />
							<IconFont type="icon-sousuo" name="搜索" className="icon-btn" />
						</div>
						<div className="control-table">
							<Table scroll={{ y: 400 }} bordered dataSource={controlCodeList} columns={tableHeader} pagination={false} rowKey={item => item.id + '_'}
							onRow={(record, index)=>{
								return {
									onClick :( event) => {
										event.stopPropagation();
										functionCodeList.resetFields();
										functionCodeList.setFieldsValue(record);
										//function code 转换成value
										if(record.functionCodeList ){
											record.functionCodeList.forEach(element => {
												
											});
										}
									}
								}
							}} />
						</div>
					</section>
					<section className="control-right" >
						<Form form={functionCodeList}>
							<Form.Item label="名称" 
								name="brand_name"
							>
							<Input placeholder="请输入名称" 
							rules={[
								{
									required: true,
									message: '请输入名称'
								}
							]}/>
							</Form.Item>
							<Form.Item label="波特率"
								name="baudrate">
								<Input placeholder="请输入波特率" 
								rules={[
								{
									required: true,
									message: '请输入波特率'
								}
							]}/>
							</Form.Item>
							<Form.Item label="开机码">
								<Input />
							</Form.Item>
							<Form.Item label="关机码">
								<Input />
							</Form.Item>
							<div className="group-name">信号源</div>
							<Form.Item label="HDMI1">
								<Input />
							</Form.Item>
							<Form.Item label="HDMI2">
								<Input />
							</Form.Item>
							<Form.Item label="VGA">
								<Input />
							</Form.Item>
							<Form.Item label="DVI">
								<Input />
							</Form.Item> 
							<div className="submit-button-box">
								<Button className='submit-button' type='primary'>确定</Button>
							</div>
						</Form>
					</section>
				</div>
			</section>
		</div>
	);
};

export default ControlCode;
