import { get, post } from '@/axios';

// 系统名称获取
export const getSystemName = (query) => {
  return get('/app/place?cmd=13' , query)
}

// 系统名称修改
export const setSystemName = (query) => {
  return post('/app/place?cmd=11' , query)
}
// 设备使用统计获取
export const getDeviceStatisticsData = (query) => {
  return post('/app/data?cmd=1' , query)
}
// 设备使用统计获取
export const getDeviceStateDataStatisticsData = (query) => {
  return post('/app/data?cmd=4' , query)
}
// 物联状态统计数据获取
export const getDeviceStateData = (query) => {
  return post('/app/data?cmd=5' , query)
}
// 用电详情统计
export const getEngrgyDetail = (query) => {
  return post('/app/data?cmd=3' , query)
}
// 设备统计
export const getDeviceStatus = (query) => {
  return post('/app/data?cmd=2' , query)
}