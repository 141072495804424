import React, { useState, Component } from 'react';
import Back from '@/components/Back/index';
import { DatePicker, Tree, ConfigProvider} from 'antd';
import BarChart from './componetns/BarChart';
import BarChartMonth from './componetns/BarChartMonth';
import Pie from './componetns/Pie';
import ElectricTable from './componetns/ElectricTable';
import { getDeviceStateDataStatisticsData, getEngrgyDetail } from '@/server/system/system'
import {  getPartitionList } from '@/server/system/partition'

import './index.scss';
import moment from 'moment';import 'moment/locale/zh-cn';

//能耗统计
class Statistics extends Component {

	constructor(props){
		super(props);
		this.state = {
			treeData:[],
			place_id: 0,
			kwh_info_list:[],
			kwh_info: null,
			year: new Date().getFullYear(),
			expandedKeys:[],
			selectedKeys: [],
			kwh_info_list_year:[],
			start_date: moment().add(-1, 'y').format('yyyy-MM-DD'),
			end_date: moment().format('yyyy-MM-DD'),
			pageNum: 1,
			kwh_detail: []
		}
	}

	//获取用电详情
	getDeviceEnergyDetail = async() => {
		let {kwh} = await getEngrgyDetail({
			page: {
				size: 10,
				page: this.state.pageNum
			},
			search: {
				place_id: this.state.place_id,
				start_date: this.state.start_date,
				end_date: this.state.end_date
			},
		});
		if(kwh != null){
			this.setState({
				kwh_detail: kwh
			});
		}else{
			this.setState({
				kwh_detail: []
			});
		}
	}

	//获取年份数据统计
	getFullYearData = async()=>{
		let {kwh_info_list } = await getDeviceStateDataStatisticsData({
			place_id: this.state.place_id,
			date_type: 2,
			year: String(this.state.year)
		});
		if(kwh_info_list == null || kwh_info_list.length == 0){
			this.setState({
				kwh_info_list_year: [],
			});
		}else{
			this.setState({
				kwh_info_list_year: kwh_info_list
			});
		}		
	}

	//获取详情数据
	getStatisticsData = async() => {
		let {kwh_info_list } = await getDeviceStateDataStatisticsData({
			place_id: this.state.place_id,
			date_type: 1
		});
		if(kwh_info_list == null || kwh_info_list.length == 0){
			this.setState({
				kwh_info_list: [],
				kwh_info:null
			});
		}else{
			this.setState({
				kwh_info_list: kwh_info_list
			});
			if(kwh_info_list[0].type_kwh_list){
				this.setState({
					kwh_info: kwh_info_list[0]
				});
			}
		}
	}
	// 显示位置选择弹窗
	getScenePlaces = async () => {
		const {placelist} =   await getPartitionList({
		   "search": {
			 "keyword": ""
		 }
		 })
		 let expandedKeys = [];
		 let data = []
		 data.push({
			title: '学校',
			key: 0,
			children: []
		 });
		 expandedKeys.push(0);
		 placelist && placelist.map((item, index) => {
		   data[0].children.push({
			 title: item.name,
			 key: item.id,
			 children: []
		   })
		   item.room && item.room.map((flag, num) => {
			 data[0].children[index].children.push({
			   title: flag.name,
			   key: flag.id,
			 })
		   })
		 });
		 this.setState({
		   treeData: data,
		   expandedKeys: expandedKeys,
		   selectedKeys: [0]
		 })
	}

	onExpand(expandedKeys){
		this.setState({
			expandedKeys: expandedKeys
		});
	}

	componentDidMount(){
		this.getStatisticsData();
		this.getScenePlaces();
		this.getFullYearData();
		this.getDeviceEnergyDetail();
	}
	
	onSelect = (keys, info) => {
		if(keys && keys.length > 0){
			const place_id = keys[0];
			if(place_id != null){
				this.setState({
					place_id: place_id,
					selectedKeys: keys,
					start_date: moment().add(-1, 'y').format('yyyy-MM-DD'),
					end_date: moment().format('yyyy-MM-DD'),
				}, ()=>{
					this.getStatisticsData();
					this.getFullYearData();
					this.getDeviceEnergyDetail();
				});
			}
		}
	};

	refreshPieData(kwh_info){
		this.setState({
			kwh_info: kwh_info
		});
	}
	onDateChange(date, year) {
		console.log(year);
		this.setState({
			year: year
		},()=>{
			this.getFullYearData();
		});
	}

	onDateRangeChange(dates, dateStrings){
		if(dateStrings.length < 2){
			return;
		}
		const {start_date, end_date} = this.state;
		if(start_date == dateStrings[0] && end_date == dateStrings[1]){
			return;
		}
		this.setState({
			start_date: dateStrings[0],
			end_date: dateStrings[1]
		},()=>{
			this.getDeviceEnergyDetail();
		})
	}

	render(){
		return (
			<section className="statistics-page">
				<section className="custom-layout">
					<header className="custom-layout-header">
						<Back />
					</header>
					<section className="custom-layout-content main">
						<div className="device_status" style={{display: 'flex'}}>
						<div className="tree">
							<Tree 
								defaultExpandAll
								onSelect={this.onSelect}
								onExpand={this.onExpand.bind(this)}
								treeData={this.state.treeData}
								expandedKeys={this.state.expandedKeys}
								selectedKeys={this.state.selectedKeys}
							>
							</Tree>	
						</div>
						<div className="sesssion" style={{width: 10, background: '#f0f0f0'}}></div>
						<div className="device_charts">	
						<section className="module2 mb-20">	
						<header className="module-title1">能耗统计</header>	
						<div style={{display:'flex'}}>
						<div className="device_chart">
								<BarChartMonth data={this.state.kwh_info_list} refresh={this.refreshPieData.bind(this)}/>
							</div>
							<div className="pie-box">
								<Pie data={this.state.kwh_info}/>
							</div>
						</div>
						</section>
						<section className="module">
							<header className="module-title">用电详情</header>
							<div className="module-main">
								<DatePicker onChange={this.onDateChange.bind(this)} defaultValue={moment()} picker="year"  placeholder={'请选择年份'} allowClear={false}style={{marginBottom:30}}/>
								<div className="charts-group mb-20">
									<BarChart data={this.state.kwh_info_list_year}/>
								</div>
								<ConfigProvider>
									<DatePicker.RangePicker defaultValue={[moment().add(-1, 'y'), moment()]}  allowClear={false} onChange={this.onDateRangeChange.bind(this)} style={{marginBottom:30}}/>
								</ConfigProvider>									
								<ElectricTable  data={this.state.kwh_detail}/>
							</div>
						</section>
							</div>
							</div>
					</section>
				</section>
			</section>
		);
	}
};

export default Statistics;
