import React, { Component } from 'react';
import { Form, Input, Button, Checkbox, message, Table, Tooltip, Modal } from 'antd';
import IconFont from '@/components/IconFont';
import '../index.scss'
import { getCardList,postCardDel} from '@/server/system/card'
import AddUserCard from '../addUserCard/Index'
import _ from 'lodash'


class Card extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      data: [
      ],
      columns: [
        {
          title: '姓名',
          align: 'center',
          key: 'name',
          dataIndex: 'name',
        },
        {
          title: '部门',
          align: 'center',
          key: 'department',
          dataIndex: 'department',
        },
        {
          title: '一卡通',
          align: 'center',
          key: 'iccard',
          dataIndex: 'iccard',
        },
        {
          title: '关联系统用户',
          align: 'center',
          key: 'user_name',
          dataIndex: 'user_name',
        },
        {
          title: '操作',
          align: 'center',
          key: 'operation',
          dataIndex: 'operation',
          render: (text, read) => <div className="user-task">
            <Tooltip placement="topLeft" title='编辑' onClick={() => this.cardEdit(read.key)} arrowPointAtCenter>
             <IconFont type='icon-bi' className="edit" />
            </Tooltip>
            <Tooltip placement="topLeft" title='删除'  onClick={() => this.cardDel(read)} arrowPointAtCenter>
             <IconFont type='icon-del' className="del" />
            </Tooltip>
          </div>,
        },
      ],
      sclect: false,
      selectedRowKeys: [],
      page: 1,
      size: 10,
      name: '',
      iccard: '',
      total: undefined
    }
  }

  // 指定删除
  cardDel = async(item) => {
    var that = this
    Modal.confirm({
			title: '提示',
			content: `确认要删除一卡通 "${item.name}"？`,
			okText: '确认',
			cancelText: '取消',
			async onOk(){
        const data = await postCardDel({
          "cards": 
            [
              {
              id: item.id,
              card: item.iccard
              }
            ]
        })
        if (_.get(data, 'result.code') === 0) {
          message.success('操作成功');
          that.getCardListFunc();
        }     
			}
		});
  }
  // 用户信息编辑
  cardEdit = (id) => {
    const data = this.state.data;
    if(data){
      for(let i = 0; i < data.length; i++){
        if(data[i].id === id){
          this.refs.addUser.showModal(this.searchList.bind(this));
          this.refs.addUser.userDetails(id, data[i]);
          return;
        }
      }
    }
  }
  
  // 全选
  checkAll = (e) => {
    const selectedRowKeys = []
    this.state.data.map(item => {
      selectedRowKeys.push(item.key)
    })
    this.setState({
      selectedRowKeys: e.target.checked ? selectedRowKeys : [],
      rowSelection: {
        type: 'checkbox',
        onChange: this.onChange,
        selectedRowKeys: e.target.checked ? selectedRowKeys : []
      }
    })
  } 

  componentDidMount() {
    this.searchList();
  }
  // 搜索
  searchList = () => {
    this.formRef.current.validateFields().then((values) => {
      this.setState({
        name: values.name || '',
        iccard: values.iccard | ''
      },() => {
      this.getCardListFunc()
      })
    })
  }
  
  //  获取用户列表
  getCardListFunc = async () => {
    const {card_list} = await getCardList({
       "page": {
         "page": this.state.page,
         "size": this.state.size
     },
     "search": {
         "name": this.state.name,
         "iccard": this.state.iccard,
     }
     })
     if(card_list == null){
        return;
     }
     let data = []
     card_list.card && card_list.card.map((item, index) => {
       data.push({
         key: item.id,
         id: item.id,
         name: item.name,
         department: item.department,
         iccard: item.iccard,
         user_id: item.user_id,
         user_name: item.user_name
       })
     })
     this.setState({
       total: card_list.total,
       data
     })
   }

  // 编辑
  editTable = () => {
    this.setState({
      sclect: !this.state.sclect,
      rowSelection: !this.state.sclect ? {
        type: 'checkbox',
        onChange: this.onChange,
      } : null
    })
  }
  
  // 分页切换
   onChangeTable = (e) => {
    this.setState({
      page: e
    }, () => {
      this.getUserListFun()
    })
  }
  //  添加用户
  adduser = () => {
    this.refs.addUser.showModal(this.searchList.bind(this))
  }

  onChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedRowKeys
    })
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  }

  // 批量删除
  batchDel = async() => {
    let keys = this.state.selectedRowKeys;
    console.log(keys);
    if(keys == null || keys.length == 0){
      message.error("请选择一卡通");
      return;
    } 
    let list = [];
    for(let i = 0; i < this.state.data.length; i++){
        if(keys.indexOf(this.state.data[i].id) != -1){
          list.push(
            {
              id: this.state.data[i].id,
              card: this.state.data[i].iccard
            }
          );
        }
    }
    const data = await postCardDel({
      "cards":list
    })
      if (_.get(data, 'result.code') === 0) {
        message.success('操作成功');
        this.getCardListFunc();
      }
  }
  

  render() {
    return (
      <div className="account">
        <div className="account-header">一卡通管理</div>
        <div className="account-search">
          <div className="search-left">
            <Form layout="inline" ref={this.formRef} className="searh-header-form">
              <Form.Item
                name='name'
              >
              <Input style={{ width: '206px' }} placeholder='请输入搜索的姓名' allowClear/>
              </Form.Item>
              <Form.Item
                name='iccard'
              >
                <Input style={{ width: '206px' }} placeholder='请输入搜索的卡号' allowClear/>
              </Form.Item>
              <Form.Item >
              <Button type="primary" onClick={() => this.searchList()} style={{ background: '#4164F0', borderColor: "#4164F0" }}>
                  <IconFont type='icon-sousuo' />搜索
				        </Button>
              </Form.Item>
              <Form.Item >
                <Button type="primary" onClick={() => this.adduser()} style={{ background: '#4586F3', borderColor: "#4586F3" }}>
                  添加用户
				        </Button>
              </Form.Item>
            </Form>
          </div>
          <div className="search-right">
          <div className={!this.state.sclect ? 'task-btn task-edit': 'task-btn task-close'} onClick={() => this.editTable()}>{!this.state.sclect? '编辑' : '取消'}</div>
          </div>
        </div>

        <div className="user-table">
        <Table
          pagination={{ defaultPageSize: 10, hideOnSinglePage: true, total: this.state.total, onChange: this.onChangeTable}}
          rowSelection={this.state.rowSelection}
          columns={this.state.columns}
          dataSource={this.state.data}
        />
        </div>
        {
          this.state.sclect ? (<div className="table-footer">
					<div className="check-all">
						<Checkbox onChange={(e) => this.checkAll(e)}>全选</Checkbox>
					</div>
					<div className="check-total">已选{this.state.selectedRowKeys.length ? this.state.selectedRowKeys.length: 0}项</div>
					<div className="action-item">
						<IconFont type="icon-del" className="icon-del" onClick={() => this.batchDel() }/>
					</div>
					<div className="cancel">
						<button className="cancel-button" onClick={() => this.editTable()}>取消</button>
					</div>
        </div>) : ''
        }   
        <AddUserCard ref="addUser" refresh={this.getCardListFunc} />    
      </div>
    );
  }
}

export default Card;
