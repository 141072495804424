import React, { Component } from 'react';
import Back from '@/components/Back/index';
import { Select, Space, Button, Row, Col, Card,Form} from 'antd';
import Proportion from './components/Proportion';
import TopLeftTable from './components/TopLeftTable';
import ContrastBar from './components/ContrastBar';
import BottomRightTable from './components/BottomRightTable';
import Pie from './components/Pie';
import { getDeviceStateData } from '@/server/system/system'
import './index.scss';

class Status extends Component{
	formRef = React.createRef();
	constructor(props) {
		super(props);
		this.state = {
			level: "1",
			utilization:[],
			iot_states: null
		}
	}

	getStatisticsData = async() => {
		let {device_states, iot_states } = await getDeviceStateData({
			place_id: 0,
			level: Number(this.state.level)
		});
		if(device_states && device_states.data){
			let types = [];
			device_states.data.forEach((item, index)=>{
				types.push({
					key: index,
					name : item.name,
					type: index,
					number: item.number,
					color: item.color,
					ratio: (item.number * 100 / device_states.total).toFixed(2)
				});
			});
			this.refs.device_states.setData(types);
			this.refs.device_states_table.setData(types);
			this.setState({
				utilization: device_states.utilization
			});
		}
		if(iot_states){
			this.setState({
				iot_states: iot_states
			});
		}
	}

	componentDidMount() {
		this.getStatisticsData();
	}
	onChange = (e) => {
		this.setState({
			level: e
		},
		()=>{
			this.getStatisticsData();
		});
	};
	render(){
		return (
			<Form className="status-page" ref={this.formRef}>
				<section className="custom-layout">
					<header className="custom-layout-header">
						<Back />
						<Space>
							<div className="select-input">
								<Select defaultValue={"1"} onChange={this.onChange.bind(this)}>
									<Select.Option value="1">学校</Select.Option>
									<Select.Option value="2">年级</Select.Option>
									<Select.Option value="3">班级</Select.Option>
								</Select>
							</div>
							{/* <Button className="mini-button green-button">物联使用情况</Button>
							<Button type="primary" className="mini-button">
								数据导出
							</Button> */}
						</Space>
					</header>
					<section className="custom-layout-content main">
						<Row  className="mb-30">
							<Col style={{width: '40%'}}>
								<Card className="number-radio-card">
									<Proportion ref="device_states"/>
								</Card>
							</Col>
							<Col style={{width: '60%'}}>
								<Card className="number-radio-card">
									<TopLeftTable ref="device_states_table"/>
								</Card>
							</Col>
							{/* <Col span={12}>
								<Card className="utilization">
									<div className="header-title">设备利用率</div>
									<div className="utilization-pies" >
										{
											this.state.utilization.map((item, index)=>(
												<Pie id={'radioPie' + index} key={index} data={item}/>
											))
										}
									</div>
								</Card>
							</Col> */}
						</Row>
						<Card className="status-page-bottom-card">
							<header className="bottom-card-header">
								<div className="header-title">物联状态</div>
								<div className="status-types">
								{
									this.state.iot_states && this.state.iot_states.data.map((item, index)=>(
										<div className="item" key={index} style={{color: item.color}}>{item.name}</div>
										))
								}
								</div>
							</header>
							{
								this.state.iot_states && 
								<ContrastBar data={this.state.iot_states}/>
							}
						</Card>
					</section>
				</section>
			</Form>
		);
	}
};

export default Status;
