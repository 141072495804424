import React, { useEffect } from 'react';
import * as echarts from 'echarts';

let myChart = null;
const Pie = (props) => {
	const legends =  [{ type: 1, name: '照明'}, { type: 2, name: '空调'},  { type: 3, name: '投影机'},  { type: 4, name: '风扇'},
	{ type: 5, name: '投影幕'},{ type: 6, name: '一体机'},{ type: 7, name: '电磁锁'}, { type: 8, name: '窗帘'}, { type: 9, name: '电脑'},
	{ type: 10, name: '插座'}, { type: 11, name: '其他'}];
	const {data} = props;
	var pieData = [], title = '';
	if(data){
		title = data.month;
	}
	if(data && data.type_kwh_list && data.type_kwh_list.length > 0){
		for(let i = 0; i < data.type_kwh_list.length; i++){
			const item = data.type_kwh_list[i];
			for(let j = 0; j < legends.length; j++){
				if(legends[j].type == Number(item.type)){
					pieData.push({
						value: Number(item.kwhs),
						name: legends[j].name
					});
					break;
				}
			}
		}
	}
	if(myChart){		
		var opt = myChart.getOption();
		opt.series[0].name = title;
		opt.series[0].data = pieData; 	
		myChart.setOption(opt);

	}
	const renderChart = () => {
		var chartDom = document.getElementById('pie');
		myChart = echarts.init(
			chartDom,
			{},
			{
				devicePixelRatio: 1,
				width: chartDom.clientWidth,
				height: chartDom.clientHeight,
			}
		);
		var option = {
			tooltip: {
				trigger: 'item'
			},
			legend: {
				bottom: 0
			},
			series: [
				{
					name: title,
					type: 'pie',
					radius: ['55%'],
					data: pieData,
					hoverAnimation: false,
					label: {
						formatter: '{c}',
					},
					emphasis: {
						itemStyle: {
							shadowBlur: 1,
							shadowOffsetX: 0,
							shadowColor: 'rgba(0,0,0,.5)'
						}
					}
				}
			]
		};

		option && myChart.setOption(option);
	};
	useEffect(() => {
		renderChart();
	}, []);
	return (
		<div className="pie-wrap">
			<div id="pie" className="pie"></div>
		</div>
	);
};

export default Pie;
