import React, { useEffect } from 'react';
import * as echarts from 'echarts';

var myChart = null;
var refreshData = null;
const legends =  ['照明', '空调', '投影机', '风扇','投影幕','一体机','窗帘','电脑'];
const BarChart = (props) => {
	const {data, refresh} = props;
	refreshData = data;
	let xAxisData = [];
	let serialData = [];
	for(let i = 0; i < legends.length; i++){
		serialData.push({
			name: legends[i],
			type: 'bar',
			data: []
		});
	}

	data.forEach((item)=>{
		xAxisData.push(item.place);		
		for(let j = 0; j < serialData.length; j++){
			let findIndex = -1;
			if(item.type_list){
				for(let i = 0; i < item.type_list.length; i++){
					if(serialData[j].name == item.type_list[i].name){
						findIndex = i;
						break;
					}
				}
			}
			if(findIndex == -1){
				serialData[j].data.push(0);
			}else{
				serialData[j].data.push(Number((Number(item.type_list[findIndex].use_duration) / 3600).toFixed(2)));
			}
		}
	});
	const renderChart = () => {
		var chartDom = document.getElementById('BarChart');
		myChart = echarts.init(
			chartDom,
			{},
			{
				devicePixelRatio: 1,
				width: chartDom.clientWidth
			}
		);
		var option = {
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					// 坐标轴指示器，坐标轴触发有效
					type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
				}
			},
			legend: {
				data: legends,
				bottom: 0
			},
			grid: {
				top: 20,
				bottom: 30,
				left: 0,
				right: 20,
				containLabel: true,
				borderWidth: 0
			},
			toolbox: {
				feature: {}
			},
			xAxis: {
				type: 'category',
				inverse: true,
				data: xAxisData,
				axisLabel:{
					interval: 0,
					formatter: function (value) {
					  //x轴的文字改为竖版显示
					  var str = value.split("");
					  return str.join("\n");
					}
				  },
			},
			yAxis: {
				type: 'value',
				name: '',
				axisLabel: {
					formatter: '{value}'
				}			
			},
			series: serialData
		};

		option && myChart.setOption(option);
		
		myChart.getZr().on('click', (params)=>{
			let pointInPixel = [params.offsetX, params.offsetY]
			if (myChart.containPixel('grid', pointInPixel)) {
			  let xIndex = myChart.convertFromPixel({ seriesIndex: 0 }, [params.offsetX, params.offsetY])[0]
			  if(refreshData && refreshData.length > xIndex){
					refresh && refresh(refreshData[xIndex]);
			  }
			}
		});
	};

	if(myChart){
		var opt = myChart.getOption();
		opt.xAxis[0].data = xAxisData;
		opt.series = serialData;
		myChart.setOption(opt);
	}

	useEffect(() => {
		renderChart();
	}, []);

	return (
		<section className="bar-chart-wrapper">
			<div className="chart-title">累积使用时间(小时)</div>
			<div id="BarChart" className="BarChart BarChart2"></div>
		</section>
	);
};

export default BarChart;
