import React, { Component } from 'react';
import { Modal, Form, Input, Button, Transfer, Select, message, } from 'antd';
import { setAddUserMessage, setRoleDepartment, getUserDetails,getUserList } from '@/server/system/user'
import { getCardList, postCardDel, postCardAdd, postCardModify} from '@/server/system/card'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { getScenePlace } from '@/server/scene'
import IconFont from '@/components/IconFont';
import _ from 'lodash'
import '../index.scss'


class AddUserCard extends Component {
  formRef = React.createRef();
  callback = null;

  constructor(props) {
    super(props);
    this.resetState();
  }

  resetState(){
    this.state = {
      id: 0,
      isModalVisible: false,
      user_department: [],
      user_departmentFu: [],
      userList: []
    }
  }

  componentDidMount() {
		// this.getplaceList()
	}

  // 角色与部门获取接口
  setRoleDepartmentFun = async() => {
    const { user_department, user_roles} = await setRoleDepartment()
    this.setState({
      user_department,
      user_roles
    })
  }

  // 获取用户信息
  userDetails = async(id, info) => {
    this.setState({
        id: id,
        userId: id,
        name: info.name,
        card_id: info.iccard,
        user_id: info.user_id,
        department: info.department
      }, ()=>{
        this.formRef.current.setFieldsValue({
          name: info.name,
          card_id: info.iccard,
          user_id: info.user_id,
          department: info.department
        })
    });
  }
  
  onPasswordChange(){
    this.setState({
      passwordChange: true
    })
  }

  // 添加用户弹窗
  showModal = (callback) => {
    this.callback = callback;
    this.setState({
        id: null,
        isModalVisible: true,        
      }, () => {
        this.setRoleDepartmentFun()
        this.setUserList()
      })
  }
  // 部门过滤
  departmentFilter = (id) => {
    let text = ''
    for(let i=0;i<this.state.user_department.length;i++){
      if (this.state.user_department[i].id === id) {
        text = this.state.user_department[i].name
        return text
      }
    }
  }

  handleOk = () => {    
      this.formRef.current.validateFields().then(async(values) => {
        // 添加用户信息
          let body = {
              "card": {
                "id": this.state.id,
                "name": String(values.name),
                "card_id": String(values.card_id),
                "user_id": values.user_id,
                "department": values.department
            }
          }
          if(this.state.id == 0 || this.state.id == undefined){
            const data = await postCardAdd(body);
            if (_.get(data, 'result.code') === 0) {
              message.success('操作成功')
              this.setState({
                  isModalVisible: false
              },()=>{
                if(this.callback){
                  this.callback();
                }
              })   
            } 
          }else{
            const data = await postCardModify(body);
            if (_.get(data, 'result.code') === 0) {
              message.success('操作成功')
              this.setState({
                  isModalVisible: false
              },()=>{
                if(this.callback){
                  this.callback();
                }
              })   
            } 
          }
      })
  }

  // 查询设备位置
  setUserList = async() => {
    const { user_list } = await getUserList({
          "page": {
            "page": 1,
            "size": 10000
        },
          "search": {
            "name":"",
            "department":0
        }
    })
    let arr = []
    user_list.user &&  user_list.user.map(item => {
        arr.push({
          id: item.id,
          name: item.name
        })
    })
    this.setState({
      userList: arr
    })
  }

  handleCancel = () => {
    this.setState({
      isModalVisible: false
    })
  }

  render() {
    return (
      <div className="add-user">
        {/* 添加用户  begin*/}
        {this.state.isModalVisible ? <Modal title="一卡通添加"  footer={null} visible={this.state.isModalVisible} onOk={() => this.handleOk()} onCancel={() => this.handleCancel()}>
         {/* 内容区域 begin */}
         <div className="content-main-user">
           {/* 步骤一 begin */}
           <div className='block steps-one'>
           <Form layout="inline" ref={this.formRef} 
           className="searh-header-form">
              <Form.Item
                label="姓名"
                rules={[{ required: true, message: '请输入姓名！' }]}
                name='name'
              >
                <Input style={{ width: '206px' }} placeholder='请输入姓名'/>
              </Form.Item>
              <Form.Item
                label="卡号"
                rules={[{ required: true, message: '请输入卡号！' }]}
                name='card_id'
              >
                <Input style={{ width: '206px' }} placeholder='请输入卡号'/>
              </Form.Item>
              <Form.Item
                name='department'
                rules={[{ required: true, message: '请选择部门名称！' }]}
                label="部门"
              >
                <Select placeholder='请选择部门名称' style={{ width: '206px' }} allowClear>
                  {
                    this.state.user_department.map((item,index) => {
                      return (
                        <Select.Option key={index} value={item.name}>{item.name}</Select.Option>
                      )
                    })
                  }
                </Select>
              </Form.Item>
              <Form.Item
                name='user_id'
                rules={[{ required: true, message: '请选择关联用户！' }]}
                label="关联用户"
              >
                <Select placeholder='请选择关联用户' style={{ width: '206px' }} allowClear>
                {
                    this.state.userList.map((item,index) => {
                      return (
                        <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                      )
                    })
                  }
                </Select>
              </Form.Item>
           </Form>
           </div>           
           
         </div>
         {/* 按钮 begin */}
          <div className="footer">
          {<Button type="primary" onClick={() => this.handleOk()}>完成</Button> }
          </div>
         {/* 按钮 end */}
         {/* 内容区域 end */}
        </Modal>
        : ''}        
      </div>
    );
  }
}

export default AddUserCard;
