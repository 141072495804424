import React, { Component } from 'react';
import { Avatar, Dropdown, Menu } from 'antd';
import { UserOutlined, LogoutOutlined, CaretDownOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setUserInfo } from '@/redux/actions/userInfo';
import { setLogo } from '@/redux/actions/logo';
import logo from "@/assets/img/logo.png"
import { getSystemName, setSystemName } from '@/server/system/system'

class TopHeader extends Component {
	state = {
		visible: false 
	};
	handleLogout = () => {
		this.props.setUserInfo({});
		localStorage.removeItem('isLogin');
		localStorage.removeItem('userInfo');
		this.props.history.push('/');
	};
	componentDidMount() {
		let userInfo = localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo'));
		if (userInfo) {
			this.props.setUserInfo(userInfo);
		} else {
			this.props.setUserInfo({});
			this.props.history.push('/login');
		}
		this.getSystemNameFun();
	}
	// 获取系统信息
	getSystemNameFun = async () => {
	 const {logo} =  await getSystemName();
		if(logo){
			this.props.setLogo(logo);
		}
	}

	render() {
		const DropdownList = (
			<Menu className="drop-list">
				<Menu.Item key="user">
				  <UserOutlined />
					{Object.keys(this.props.userInfo).length > 0 && this.props.userInfo.role.name}
				</Menu.Item>
				<Menu.Item key="logout" onClick={this.handleLogout}>
				<LogoutOutlined />
					退出登录
				</Menu.Item>
			</Menu>
		);
		return (
			<div className="top-header">
				<div className="top-header-inner">
					<div className="header-title">
						<div className="loginImg"><img alt="logo" src={this.props.logo} style={{ backgroundSize:'cover',width:50,height:50 }}></img></div>
						<div className="loginTitle">智慧教育设备管理系统</div>
					</div>
					<div className="header-right">
						<div className="schoolName">{localStorage.getItem('serverName') || ''}</div>
						<div className="dropdown-wrap" id="dropdown-wrap" style={{ cursor: 'pointer' }}>
							<Dropdown getPopupContainer={() => document.getElementById('dropdown-wrap')} overlay={DropdownList}>
								<div className="userList">
									<div className="userName">{Object.keys(this.props.userInfo).length > 0 && this.props.userInfo.role.name}</div>
									<CaretDownOutlined  style={{ color: 'rgba(255,255,255,.6)', cursor: 'pointer' }}/>
								</div>
							</Dropdown>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setUserInfo: data => {
		dispatch(setUserInfo(data));
	},
	setLogo: data=>{
		dispatch(setLogo(data));
	}
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(TopHeader));
