import React, { useState, Component } from 'react';
import Back from '@/components/Back/index';
import { Input,Form, Tree,Modal} from 'antd';
import BarChart from './componetns/BarChart';
import Pie from './componetns/Pie';
import ElectricTable from './componetns/ElectricTable';
import { getDeviceStatisticsData, getDeviceStatus} from '@/server/system/system'
import {  getPartitionList } from '@/server/system/partition'

import './index.scss';

//设备统计
class Statistics extends Component {
	formRef = React.createRef();
	
	constructor(props){
		super(props);
		this.state = {
			place_id: 0,
			place_name: '',
			treeData: [],
			expandedKeys:[],
			selectedKeys: [],
			locationSearch: false,
			chartData: [],
			selectedRoom: '',
			place_level3: [],
		}
	}

	// 显示位置选择弹窗
	getScenePlaces = async () => {
		const {placelist} =   await getPartitionList({
		   "search": {
			 "keyword": ""
		 }
		 })
		 let expandedKeys = [];
		 let place_level3 = [];
		 let data = []
		 data.push({
			title: '学校',
			key: 0,
			disableCheckbox: false,
			checkable: true,
			children: []
		 });
		 expandedKeys.push(0);
		 placelist && placelist.map((item, index) => {
		   data[0].children.push({
			 title: item.name,
			 key: item.id,
			 children: []
		   })
		   expandedKeys.push(item.id);
		   item.room && item.room.map((flag, num) => {
			 data[0].children[index].children.push({
			   title: flag.name,
			   key: flag.id,
			 })
			 expandedKeys.push(flag.id);
			 place_level3.push(flag.id);
		   })
		 });
		 this.setState({
		   treeData: data,
		   expandedKeys: expandedKeys,
		   selectedKeys: expandedKeys,
		   place_level3: place_level3
		 },()=>{
			 if(data && data.length > 0){
				this.setState({
					place_name: data[0].title,
					place_id: [expandedKeys]
				},()=>{
					this.getDeviceStatusData();
				});
			 }
		 })
	}

	setPlaceName(selectedKeys){
		const {treeData} = this.state;
		for(let i = 0; i < treeData.length; i++){
			const item = treeData[i];			
			if(selectedKeys.indexOf(item.key) != -1){
				this.setState({
					place_name: item.title
				});
				return;
			}				
			if(item.children && item.children.length > 0){
				for(let j = 0; j < item.children.length; j++){
					const flag = item.children[j];
					if(selectedKeys.indexOf(flag.key) != -1){
						this.setState({
							place_name: flag.title
						});
						return;
					}	
					if(flag.children && flag.children.length > 0){
						flag.children.forEach((f)=>{
							if(selectedKeys.indexOf(f.key) != -1){
								this.setState({
									place_name: f.title
								});
							}	
						})
					}
				}
			}
		}
	}

	//获取设备状态数据
	getDeviceStatusData = async() => {
		const {selectedKeys} = this.state;
		const {place_level3} = this.state;
		let keys = [];
		if(selectedKeys && selectedKeys.length > 0){
			this.setPlaceName(selectedKeys);
		}else{
			this.setState({
				place_name: ''
			});
		}
		selectedKeys.forEach((item)=>{
			if(place_level3.indexOf(item) != -1){
				keys.push(item);
			}
		});
		let {Statistics} = await getDeviceStatus({
			place_id: keys
		});
		if(Statistics != null && Statistics.length > 0){
			//设置底部是表格数据为第一个选中的班
			this.setState({
				chartData: Statistics
			},()=>{
				this.setState({
					place_id: Statistics[0].place_id,
					selectedRoom: Statistics[0].place
				},()=>{
					this.getStatisticsData();
				})
			});
		}else{
			this.setState({
				chartData: []
			},()=>{
				this.refs.dataList.setData([]);
			});
		}
	}

	getStatisticsData = async() => {
		let {iot_unit_statistics} = await getDeviceStatisticsData({
			place_id: this.state.place_id
		});
		if(iot_unit_statistics && iot_unit_statistics.length > 0){
			this.refs.dataList.setData(iot_unit_statistics);
		}else{			
			this.refs.dataList.setData([]);
		}
	}

	onExpand(expandedKeys){
		this.setState({
			expandedKeys: expandedKeys
		});
	}

	componentDidMount() {
		this.getScenePlaces();
	}

	// 选择位置弹窗
	locationSearchOk(){
	  this.setState({
		locationSearch: false,
	  })
	  this.getDeviceStatusData();
	}
	
	locationSearchCancel() {
		this.setState({
			locationSearch: false,
		})
   }

   placeSelect(){
	this.setState({
		locationSearch: true,
	  })
   }

   //点击图表的时候，刷新下方表格
   onRefreshChart(data){
	   if(data.place_id){
		   this.setState({
				place_id: data.place_id
		   },()=>{
			   this.getStatisticsData();
		   });
	   }
	   if(data.place){
		this.setState({
			selectedRoom: data.place
		});
	   }
   }

	onCheck(checkedKeysValue){
		this.setState({
			selectedKeys: checkedKeysValue
		}, ()=>{			
		});
	};

	render() {
	return (
		<Form className="statistics-page" ref={this.formRef} >
			<section className="custom-layout">
				<header className="custom-layout-header">
					<Back />
						<div className="name-item">
							<div className="name-label" onClick={() => this.placeSelect()}>{'选择位置:  '}{this.state.place_name}</div>
						</div>
					    <Modal title="选择位置" zIndex="2002"  okText="确定" cancelText="取消" visible={this.state.locationSearch} 
						onOk={() => this.locationSearchOk()} onCancel={() => this.locationSearchCancel()}>
						<Tree
							checkable
							onExpand={this.onExpand.bind(this)}
							onCheck={this.onCheck.bind(this)}
							treeData={this.state.treeData}
							expandedKeys={this.state.expandedKeys}
							selectedKeys={this.state.selectedKeys}
							checkedKeys={this.state.selectedKeys}
						/>
						</Modal>
				</header>
				<section className="custom-layout-content main">
					<section className="module mb-20">
						<header className="module-title">设备状态</header>
						<div className="module-main">
							<div className="flex-box">
								<BarChart data={this.state.chartData} refresh={this.onRefreshChart.bind(this)}/>
							</div>
							<div className="flex-box">
							<header className="module-title">{'设备详情-'}{this.state.selectedRoom}</header>
								<ElectricTable ref="dataList"/>
							</div>
						</div>
					</section>
					</section>
				</section>
			</Form>
			);
		};
}
export default Statistics;
