import React, { useEffect } from 'react';
import * as echarts from 'echarts';

const Pie = props => {
	const {data} = props;
	const renderChart = () => {
		var chartDom = document.getElementById(props.id);
		var myChart = echarts.init(
			chartDom,
			{},
			{
				devicePixelRatio: 1,
				width: chartDom.clientWidth,
				height: chartDom.clientHeight
			}
		);
		var option;
		option = {
			tooltip: {
				trigger: 'item'
			},

			series: [
				{
					name: '设备利用率',
					type: 'pie',
					radius: ['30%', '45%'],
					color: [data.color1, data.color2],
					data: [
						{ value: data.number1, name: data.name1},
						{ value: data.number2, name: data.name2 }
					],
					hoverAnimation: false,
					label: {
						formatter: '{c}'
					},
					emphasis: {
						itemStyle: {
							shadowBlur: 1,
							shadowOffsetX: 0,
							shadowColor: 'rgba(0,0,0,.5)'
						}
					}
				}
			]
		};

		option && myChart.setOption(option);
	};
	useEffect(() => {
		setTimeout(() => {
			renderChart();
		}, 50);
	}, []);

	return (
		<div className="pie-wrap">
			<div className="pie" id={props.id}></div>
			<div className="pie-legend">
				<div className="item on" style={{ color: data.color1 }}>
					{data.name1}
				</div>
				<div className="item off" style={{ color: data.color2 }}>
					{data.name2}
				</div>
			</div>
		</div>
	);
};

export default Pie;
