import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import IconFont from '@/components/IconFont'
import './index.scss';

class Steps extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
	render() {
		return (
			<div className="steps-container">
			</div>
		);
	}
}

export default withRouter(Steps)
