import Item from 'antd/lib/list/Item';
import { color } from 'echarts';
import React, {Component} from 'react';
import Gauge from './Gauge';

class Proportion extends Component{

	state = {
		types: []
	};

	setData(data){
		this.setState({
			types: data
		});
	}
	
	render(){
		const {types} = this.state;
		return (
				<section className="proportion">
					<header className="prop-header">
						<span className="prop-title">数占比</span>
						<div className="type-list">
							{types.map(item => (
								<div className={`item item-type-${item.type}`} key={item.type} style={{color:item.color}} >
									{item.name}
								</div>
							))}
						</div>
					</header>
					<section className="gauge-list">
						{
							this.state.types.map(item =>(
								<div key={item.type}>
									<Gauge key={item.type} value={item.ratio} id={"gague" + item.type} color={item.color} name={item.name} />
								</div>
							))
						}
					</section>
				</section>
			);
		}
};

export default Proportion;
