import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import { bool } from 'prop-types';
var myChart = null;
const legends =  [{ type: 1, name: '照明'}, { type: 2, name: '空调'},  { type: 3, name: '投影机'},  { type: 4, name: '风扇'},
{ type: 5, name: '投影幕'},{ type: 6, name: '一体机'},{ type: 7, name: '电磁锁'}, { type: 8, name: '窗帘'}, { type: 9, name: '电脑'},
{ type: 10, name: '插座'}, { type: 11, name: '其他'}];
const BarChart = (props) => {
	const {data} = props;
	let xAxisData = [];
	let mapData = [];
	for(let i = 0; i < legends.length; i++){
		mapData.push({
			device_type: legends[i].type,
			name: legends[i].name,
			type: 'bar',
			data: []
		});
	}
	for(let i = 0; i < data.length; i++){
		const item = data[i];
		xAxisData.push(String(item.month));
		for(let i = 0; i < mapData.length; i++){
			const device_type = mapData[i].device_type;
			let findIndex = -1;
			for(let j = 0; j < item.type_kwh_list.length; j++){
				if(Number(item.type_kwh_list[j].type) == device_type){
					findIndex = j;
					break;
				}
			}
			if(findIndex == -1){
				mapData[i].data.push(0);
			}else{
				mapData[i].data.push(item.type_kwh_list[findIndex].kwhs);
			}
		}
	}
	const renderChart = () => {
		var chartDom = document.getElementById('BarChart');
		myChart = echarts.init(
			chartDom,
			{},
			{
				devicePixelRatio: 1,
				width: chartDom.clientWidth
			}
		);
		var option = {
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					// 坐标轴指示器，坐标轴触发有效
					type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
				}
			},
			legend: {
				data: legends,
				bottom: 0,
			},
			grid: {
				top: 10,
				bottom: 30,
				left: 0,
				containLabel: true,
				borderWidth: 0
			},
			toolbox: {
				feature: {}
			},
			xAxis: {
				type: 'category',
				axisTick: {show: false}
			},
			yAxis: {
				type: 'value',
				axisTick: false,
				axisLine: {
					show: false
				}
			},
			series: mapData
		};

		option && myChart.setOption(option);
	};
	
	if(myChart){
		var opt = myChart.getOption();
		opt.xAxis[0].data = xAxisData;
		opt.series = mapData; 	
		myChart.setOption(opt);
	}

	useEffect(() => {
		renderChart();
	}, []);
	return <div id="BarChart" className="BarChart"></div>;
};

export default BarChart;
