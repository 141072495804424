import React, { Component } from 'react';
import './index.scss'


class Device extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <div className="shadow-radius">
        物联设备管理
      </div>
    );
  }
}

export default Device;
