import React, {Component} from 'react';
import { Table } from 'antd';
import { render, useState } from 'react-dom';

class ElectricTable extends Component {
	state = {
		tableData:[]
	}

	tableHeader = [
		{
			title: '名字',
			dataIndex: 'name',
			key: 'name',
			align: 'center'
		},
		{
			title: '使用次数',
			dataIndex: 'open_times',
			key: 'open_times',
			align: 'center'
		},,
		{
			title: '使用时长(小时)',
			dataIndex: 'use_duration',
			key: 'use_duration',
			align: 'center',
			render: text => <div>{(text / 3600).toFixed(2)}</div>,
		},
		{
			title: '最后使用时间',
			dataIndex: 'last_online_time',
			key: 'last_online_time',
			align: 'center'
		}
	];
	setData(data){
		data.forEach((item, index)=>{
			item.key = index
		})
		this.setState({
			tableData: data
		});
	}

	render(){
		return (
			<section className="electric-table-wrapper2" style={{ width: '100%' }}>
				<Table className="electric-table2"  dataSource={this.state.tableData} columns={this.tableHeader} ></Table>
			</section>
		);
	}
};

export default ElectricTable;
