import { get, post } from '@/axios';

/** 一卡通 列表 */
export const getCardList = (query = {}) => {
	return post(`/app/iccard?cmd=6`, query);
};
/** 一卡通 新增 */
export const postCardAdd = (query = {}) => {
	return post(`/app/iccard?cmd=3`, query);
};
/** 一卡通 修改 */
export const postCardModify = (query = {}) => {
	return post(`/app/iccard?cmd=4`, query);
};
/** 一卡通 删除 */
export const postCardDel = (query = {}) => {
	return post(`/app/iccard?cmd=5`, query);
};