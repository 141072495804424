import React, { useEffect, useState } from 'react';
import IconFont from '@/components/IconFont';
import _ from 'lodash';
import { setDeviceControl, getSensorData } from '@/server/device';
import { message, Progress  } from 'antd';
import { time } from 'echarts';
const RightTabMain = props => {
	const { activeTab = 1, data = {}, sensorData = {} } = props;
	const state = data.device_state;
	const on_off_state = data.on_off_state;
	const list = _.get(data, 'iot_module_list', []);
	const listSize = _.size(list);
	
	/** 渲染物联 */
	const renderInternet = (data = {}, state = 0) => {
		const deviceServer = async params => {
			//如果中控离线，则无法操作
			if(on_off_state == 1){
				message.error('设备已离线，无法控制');
				return;
			}
			try {
				const res = await setDeviceControl({ ...params });
				if (_.get(res, 'result.code') == 0) {
					message.success(res.result.text);
					props.fetchDeviceDetail(params.device_id);
					props.refreshList();
				}
			} catch (error) {
				throw error;
			}
		};
		/*设备点击*/
		const onButtonClick = async (e, item, power = false) => {
			e.stopPropagation();
			let params = {
				device_id: data.device_id,
				device_control: {
					control_cmd: '',
					control_unit_info: {
						unit_id: item.unit_id,
						onoff: !item.onoff,
						type: item.type,
						name: item.name || ""
					}
				}
			};
			if (power) {
				params.device_control.control_cmd = 2048; //所有开关 cmd = 2048
			} else {
				const t = item.type;
				// "type": 0:总开关，1：照明，2：空调 3：投影仪 4:风扇 6:一体机 7:磁控锁/门禁 8:窗帘 9:电脑 10:其它
				if (_.includes([3, 4, 6, 10, 5, 8], t)) {
					params.device_control.control_cmd = 16777216; //投影机/风扇/大屏一体机/自定义设备 功能选择 cmd = 2048
					params.device_control.control_unit_info.select_function = item.function_id;
				}
				if(item.type == 4 && item.function_id && item.onoff == false){
					message.error('设备已关闭，无法控制');
					return;
				} 
			}
			try {
				console.log(params);
				deviceServer(params);
			} catch (error) {
				throw error;
			}
		};
		const getDeviceState = onoff => {
			if(on_off_state == 0){
				if(onoff){
					return 'light'
				}else{
					return 'off'
				}
			}
			return 'disabled'
		};
		/* 中控开关 */
		const zongKongClick = async () => {
			const params = {
				device_id: data.device_id,
				device_control: {
					control_cmd: 1,
					device_onoff: data.device_onoff
				}
			};
			deviceServer(params);
		};
		/** 本地控制开关 */
		const bendiClick = async () => {
			let device_state = data.device_state;
			if(device_state > 0){
				device_state = 0;
			}else{
				device_state = 1;
			}
			const params = {
				device_id: data.device_id,
				device_control: {
					control_cmd: 2,
					device_state: device_state
				}
			};
			deviceServer(params);
		};
		/*发送空调命令*/
		const hvacSendCommand = (e, item, cmd) => {
			e.stopPropagation();
			//如果不是开关机指令，则关机状态下不允许发送其他指令
			if(!item.onoff){
				if(cmd != 1 && cmd != 2){
					message.error('请先开空调');
					return;
				}
			}
			const params = {
				device_id: data.device_id,
				device_control: {
					control_cmd: 16777216,
					control_unit_info: {
						unit_id: item.unit_id,
						type: item.type,
						select_function: cmd,
						temperature: item.temperature,
						onoff: item.onoff
					}
				}
			};
			deviceServer(params);
		};
		return (
			<section className={`control-list`}>
				{/* <div className="control-row control-row-small">
					<div  className={`control-item  ${on_off_state == 1? 'disabled': ''}`}>
						<div className="li light">
							<IconFont type="icon-kongzhi1" className="icon-center" />
							<div className="control-name" name="中控">
								中控锁屏
							</div>
						</div>
						<div className={`li ${getDeviceState(state)}`}>
							<IconFont type="icon-icon_list_qiyong" className="icon-guanbi" onClick={bendiClick} />
						</div>
					</div>
				</div> */}

				{_.map(list, (item, key) => {
					const childrenList = _.get(item, 'module_funtion_list', []);
					// "type": 0:总开关，1：照明，2：空调 3：投影仪 4:风扇 6:一体机 7:磁控锁/门禁 8:窗帘 9:电脑 10:其它
					return (
						<div key={key + '_div'}>
							{/* {item.type === 6 && (
								<div className="control-row" key={key}>
									<div  className={`control-item  ${on_off_state == 1? 'disabled': ''}`}>
										<div className="li light">
											<IconFont type="icon-yitiji" className="icon-yitiji" />
											<div className="control-name" name="一体机">
												{item.name}
											</div>
										</div>
										<div className="li li-group">
											{_.map(childrenList, (item2, key2) => {
												return (
													<div className={`li mini-li ${state === 0 ? 'disabled' : 'light'}`} key={key2} onClick={e => onButtonClick(e, { ...item, function_id: item2.function_id })}>
														<IconFont type="icon-danxuan" className="icon-radio" />
														<div className="control-name">{item2.function}</div>
													</div>
												);
											})}
										</div>
										<div className={`li ${getDeviceState(item.onoff)}`}>
											<IconFont type="icon-guanbi" className="icon-guanbi" onClick={e => onButtonClick(e, item, true)} />
										</div>
									</div>
								</div>
							)} */}
							{item.type === 3 && (
								<div className={`control-row ${getDeviceState(item.onoff)}`} key={key}>
									<div className={`control-item  ${on_off_state == 1? 'disabled': ''}`}>
										<div className="li light">
											<IconFont type="icon-shexiangji" className="icon-shexiangji font-30" />
											<div className="control-name" name="投影仪">
												{item.name}
											</div>
										</div>
										<div className={`li light`}>
											<IconFont type="icon-guanbi" className="icon-guanbi" onClick={e => onButtonClick(e, item, true)} />
										</div>
									</div>
								</div>
							)}
							{item.type === 2 && (
								<div className="control-row" key={key}>
									<div  className={`control-item  ${on_off_state == 1? 'disabled': ''}`}>
										<div className="li light">
											<IconFont type="icon-kongtiao-shouye" className="icon-shexiangji font-22" />
											<div className="control-name" name="空调">
												{item.name}
											</div>
										</div>
										<div className="li control-conditioner">
											<div className="conditioner-mode">
												{_.map(childrenList, (item2, key2) => {
													let icon = '';
													const function_id = item2.function_id;
													let function_ = 1;
													if (function_id == 1) {
														icon = 'icon-zhilengmoshi';
														function_ = 3;
													}else if (function_id == 4) {
														icon = 'icon-zhiremoshi';
														function_ = 5;
													}  else if (function_id == 2) {
														icon = 'icon-chushi';
														function_ = 6;
													} else if (function_id == 3) {
														icon = 'icon-songfeng';
														function_ = 4;
													}
													return (
														<React.Fragment key={key2}>
															<IconFont key={key2} type={icon} className={`font-20 ${ item.onoff && function_id == item.select_function && 'light'}`} name={item2.function} 
															onClick={e => hvacSendCommand(e, item, function_)} />
														</React.Fragment>
													);
												})}
											</div>
											<div className="conditioner-wind-speed">
												<IconFont type="icon-yidang1" className={`font-18  ${ item.onoff && 1 == item.gear && 'light'} `} onClick={e => hvacSendCommand(e, item, 10)} style={{marginLeft: 5}} />
												<IconFont type="icon-erdang1" className={`font-18 ${ item.onoff && 2 == item.gear && 'light'}`} onClick={e => hvacSendCommand(e, item, 11)}style={{marginLeft: 5}}  />
												<IconFont type="icon-sandang" className={`font-18 ${ item.onoff && 3 == item.gear && 'light'}`} onClick={e => hvacSendCommand(e, item, 12)}style={{marginLeft: 5}}  />
												<IconFont type="icon-sidang" className={`font-18 ${ item.onoff && 4 == item.gear && 'light'}`} onClick={e => hvacSendCommand(e, item, 13)} style={{marginLeft: 5}} />
												<IconFont type="icon-wudang" className={`font-18 ${ item.onoff && 5 == item.gear && 'light'}`} onClick={e => hvacSendCommand(e, item, 14)} style={{marginLeft: 5}} />
											</div>
											<div className="conditioner-action">
												<IconFont type="icon-kongtiaotiaojie-" className="font-18" onClick={e => hvacSendCommand(e, item, 8)} />
												<div className="c">{item.temperature + 16}℃</div>
												<IconFont type="icon-kongtiaotiaojie" className="font-18" onClick={e => hvacSendCommand(e, item, 7)} />
											</div>
										</div>
										<div className={`li ${getDeviceState(item.onoff)}`}>
											<IconFont type="icon-guanbi" className="icon-guanbi" onClick={ e=>{
												if(item.onoff){
													hvacSendCommand(e, item, 2)													
												}else{
													hvacSendCommand(e, item, 1)
												}
											}} />
										</div>
									</div>
								</div>
							)}
							{item.type === 4 && (
								<div className="control-row" key={key}>
									<div  className={`control-item  ${on_off_state == 1? 'disabled': ''}`}>
										<div className="li light">
											<IconFont type="icon-songfengmoshi" className="icon-shexiangji font-22" />
											<div className="control-name" name="风扇">
												{item.name}
											</div>
										</div>
										{
										<div style={{display:'flex'}}>
										<div className={`li ${item.onoff == true && 'light' } ${item.onoff == true? '': 'disabled'} `}  onClick={e => onButtonClick(e, { ...item, function_id: 1 })}>
											<IconFont type={'icon-a-'} className="icon-shexiangji font-27" />
										</div>
										<div  className={`li select_function`} style={{ width: 80}}>
											{item.select_function < 1 ? 1 : item.select_function}
											<Progress style={{width:60}} percent={(item.select_function < 1 ? 1 : item.select_function) * 100 / 5}  strokeWidth={13} 
														strokeColor={item.onoff == true ? '#4164F0' : '#BEBEBE'}  showInfo={false} />
										</div>
										<div className={`li ${item.onoff == true && 'light' } ${item.onoff == true? '': 'disabled'} `}  onClick={e => onButtonClick(e, { ...item, function_id: 2 })}>
											<IconFont type={'icon-a--'} className="icon-shexiangji font-27" />
										</div>
										</div>
										}
										<div className={`li ${getDeviceState(item.onoff)}`}>
											<IconFont type="icon-guanbi" className="icon-guanbi" onClick={e => onButtonClick(e, item, true)} />
										</div>
									</div>
								</div>
							)}

							{(item.type === 8 || item.type === 5) && (
								<div className="control-row" key={key}>
									<div  className={`control-item  ${on_off_state == 1? 'disabled': ''}`}>
										<div className="li light">
											<IconFont type={`${item.type == 8 ? 'icon-chuanglian-shouye' : 'icon-tongyongleiyihuamian'}`} className="icon-shexiangji font-22" />
											<div className="control-name" name="窗帘|投影幕">
												{item.name}
											</div>
										</div>
										{_.map(childrenList, (item2, key2) => {
											const icons = ['icon-xiaochengxutubiao-46', 'icon-xiaochengxutubiao-47', 'icon-xiaochengxutubiao-48'];
											return (
												<div className={`li ${item.select_function == item2.function_id && 'light'}`} key={key2} onClick={e => onButtonClick(e, { ...item, function_id: item2.function_id })}>
													<IconFont type={icons[key2]} key={key2} className="icon-shexiangji light font-32" />
												</div>
											);
										})}
									</div>
								</div>
							)}
							{(item.type === 7 || item.type === 1 || item.type == 6) && (
								<div className="control-row" key={key}>
									<div  className={`control-item  ${on_off_state == 1? 'disabled': ''}`}>
										<div className="li light">
											<IconFont type={`${item.type == 7 ? 'icon-zuoce-anfangmenjin' : item.type == 6? `icon-yitiji`: 'icon-diandeng-shouye'}`} className="icon-kongzhi1" />
											<div className="control-name" name="门禁|照明">
												{item.name}
											</div>
										</div>
										<div className={`li ${getDeviceState(item.onoff)}`}>
											<IconFont type="icon-guanbi" className="icon-guanbi" onClick={e => onButtonClick(e, item, true)} />
										</div>
									</div>
								</div>
							)}
							{item.type === 9 && (
								<div className="control-row" key={key}>
									<div  className={`control-item  ${on_off_state == 1? 'disabled': ''}`}>
										<div className="li light">
											<IconFont type="icon-xiaochengxutubiao-19" className="icon-kongzhi1" />
											<div className="control-name" name="电脑">
												{item.name}
											</div>
										</div>
										<div className={`li ${getDeviceState(item.onoff)}`}>
											<IconFont type="icon-guanbi" className="icon-guanbi" onClick={e => onButtonClick(e, item, true)} />
										</div>
									</div>
								</div>
							)}	
							{item.type === 10 && (
								<div className="control-row" key={key}>
									<div  className={`control-item  ${on_off_state == 1? 'disabled': ''}`}>
										<div className="li light">
											<IconFont type="icon-chazuo" className="icon-kongzhi1" />
											<div className="control-name" name="插座">
												{item.name}
											</div>
										</div>
										<div className={`li ${getDeviceState(item.onoff)}`}>
											<IconFont type="icon-guanbi" className="icon-guanbi" onClick={e => onButtonClick(e, item, true)} />
										</div>
									</div>
								</div>
							)}
							{item.type === 11 && (
								<div className="control-row" key={key}>
									<div  className={`control-item  ${on_off_state == 1? 'disabled': ''}`}>
										<div className="li light">
											<IconFont type="icon-kongkai" className="icon-kongzhi1" />
											<div className="control-name" name="空开">
												{item.name}
											</div>
										</div>
										<div className={`li ${getDeviceState(item.onoff)}`}>
											<IconFont type="icon-guanbi" className="icon-guanbi" onClick={e => onButtonClick(e, item, true)} />
										</div>
									</div>
								</div>
							)}				
							{item.type === 12 && (
								<div className="control-row" key={key}>
									<div  className={`control-item  ${on_off_state == 1? 'disabled': ''}`}>
										<div className="li light">
											<IconFont type="icon-qita" className="icon-shexiangji font-22" />
											<div className="control-name" name="其他类型">
												{item.name}
											</div>
										</div>
										{_.map(childrenList, (item2, key2) => {
											return (
												<div key={key2} className={`li li-channel ${item.select_function == item2.function_id && 'light'}`} key={key2} onClick={e => onButtonClick(e, { ...item, function_id: item2.function_id })}>
													<div className="channel">
														<p className="text">{item2.function}</p>
													</div>
												</div>
											);
										})}
										<div className={`li ${getDeviceState(item.onoff)}`}>
											<IconFont type="icon-guanbi" className="icon-guanbi" onClick={e => onButtonClick(e, item, true)} />
										</div>
									</div>
								</div>
							)}
						</div>
					);
				})}
			</section>
		);
	};

	const renderControl = () => {
		return (
			<section className="right-control-main">
				<div className="item">
					<IconFont type="icon-wendu" className="icon-wendu" />
					<p className="name">温度 (℃)</p>
					<p className="value-box">{sensorData.temperatrue != null? sensorData.temperatrue : '--'}</p>
				</div>
				<div className="item">
					<IconFont type="icon-shidu" className="icon-shidu" />
					<p className="name">湿度 (%)</p>
					<p className="value-box">{sensorData.humidity != null? sensorData.humidity : '--'}</p>
				</div>
				<div className="item">
					<IconFont type="icon-guangzhao" className="icon-guangzhao" />
					<p className="name">光照度 (lx)</p>
					<p className="value-box">{sensorData.luminance != null? sensorData.luminance : '--'}</p>
				</div>
				<div className="item">
					<IconFont type="icon-PM25" className="icon-wendu" />
					<p className="name">PM2.5 (pg/m2)</p>
					<p className="value-box">--</p>
				</div>
			</section>
		);
	};

	const RenderOffLine = () => {
		return (
			<section className="off-line-container">
				<div className="off-line-icon">
					<IconFont type="icon-lixian" className="offline-icon"></IconFont>
				</div>
				<div className="off-line-text">设备离线</div>
			</section>
		);
	};

	const RenderNoData = () => {
		return (
			<section className="off-line-container">
				<div className="off-line-icon">
					<IconFont type="icon-wushuju" className="offline-icon"></IconFont>
				</div>
				<div className="off-line-text">无设备</div>
			</section>
		);
	};

	return (
		<React.Fragment>
			{on_off_state === 1 && RenderOffLine()}
			{listSize === 0 && RenderNoData()}
			{listSize > 0 && on_off_state !== 1 && <section className="right-tab-main">{activeTab === 1 ? renderInternet(data, state) : renderControl(data, state)}</section>}
		</React.Fragment>
	);
};

export default RightTabMain;
