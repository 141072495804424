import React, { useEffect, useRef, useState } from 'react';
import IconFont from '@/components/IconFont';
import { Table, Switch, TimePicker, Modal, message, Space, Input, Form, Select, Checkbox } from 'antd';
import { getQuery } from '../../../../utils';
import { getIotList, getIotListOptions, postAddIot,postDelIot, postEditIot } from '@/server/device';
import _ from 'lodash';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
const IotModule = () => {
	const id = getQuery('id');
	const [unitId, setUnitId] = useState(-1);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [modalType, setModalType] = useState('');
	const [iotList, setIotList] = useState([]); // 物联设备列表
	const [iotOptions, setiotOptions] = useState([]); // 添加物联设备时的可选参数
	const [brandList, setBrandList] = useState([]); //品牌列表
	const [addForm] = Form.useForm();
	const [addIotFormData] = useState({
		device_id: +id,
		name: '', //物联设备名称
		type: '', //物联设备类型
		port1: '', //物联设备电源控制端口1
		port2: '', //物联设备电源控制端口2
		panid: '', //物联模块物联ID
		boot_time: null, //物联设备开机时间
		off_time: '', //物联设备关机时间
		boot_sync: false, //物联设备开机同步
		off_sync: false, //物联设备关机同步
		send_code_times: '', //物联设备发码次数
		send_code_interval: '', //物联设备发码间隔
		comm_type: '', //物联设备通信控制方式
		comm_port: '', //物联设备通信控制端口
		brand_id: '', //物联设备品牌ID
		comm_address: '', //物联设备通信控制地址
		correcting: 0,//点击校正
	});

	const onAddModal = () => {
		setIsModalVisible(true);
		addForm.resetFields();
		addForm.setFields([
			{
				'panid': null
			},			
			{
				'boot_time_moment': moment(`00:00:00`, 'hh:mm:ss')
			},
			{
				'off_time_moment': moment(`00:00:00`, 'hh:mm:ss')
			}
		]);
		setBrandList([]);
		setModalType('add');
	};

	const onEditModal = (item) => {
		setIsModalVisible(true);
		let boot_time = item.boot_time, off_time = item.off_time;
		item.boot_time_moment = moment(`${boot_time / 3600}:${(boot_time % 3600) / 60}:${(boot_time % 3600) % 60}`, 'hh:mm:ss');
		item.off_time_moment = moment(`${off_time / 3600}:${(off_time % 3600) / 60}:${(off_time % 3600) % 60}`, 'hh:mm:ss');
		addForm.setFieldsValue(item);
		setUnitId(item.unit_id);
		setModalType('edit');
	};
	const onDelRow= item => {
		Modal.confirm({
			title: '提示',
			content: `确认要删除物联设备"${item.name}"？`,
			okText: '确认',
			cancelText: '取消',
			async onOk() {
				const res = await postDelIot({
					device_id: +id,
					iot_device: item
				});

				if (_.get(res, 'result.code') === 0) {
					message.success('操作成功');
					fetchIotList();
				}
			}
		});
	};
	/** 获取物联设备可选参数 用于添加物联设备 */
	const fetchIotOptions = async () => {
		const res = await getIotListOptions({ device_id: +id });
		console.log('可选参数', res);
		setiotOptions(res);
	};
	/** 获取物联设备列表 */
	const fetchIotList = async () => {
		const res = await getIotList({ device_id: +id });
		if(res && res.iot_device_list){
			setIotList(res.iot_device_list);
		}else{
			setIotList([]);
		}
	};
	
	// 选择设备类型 找到对应的品牌字段
	// 6 一体机  ： aiopc_brand
	// 3 投影仪 ： projector_brand
	// 2 空调 ： hvac_brand
	// 自定义 user_define_brand
	const chooseType = value => {
		// 0:总开关，1：照明，2：空调 3：投影仪
		// 4:风扇 6:一体机 7:磁控锁/门禁 8:窗帘 9:电脑 10:其它
		addForm.setFieldsValue({ brand_id: '' });
		const brandKeys = {
			6: 'aiopc_brand',
			3: 'projector_brand',
			2: 'hvac_brand',
			10: 'user_define_brand'
		};
		if (!brandKeys[value]) {
			setBrandList([]);
		} else {
			const key = brandKeys[value] || '';
			const newBrandList = iotOptions[key] || '';
			console.log('newBrandList', newBrandList);
			setBrandList(newBrandList);
		}
	};
	//添加物联设备
	const addIot = async () =>{
		try {
			const values = await addForm.validateFields();
			let boot_time = moment(`00:00:00`, 'hh:mm:ss');
			if(values.boot_time_moment){
				boot_time	=  moment(values.boot_time_moment);
			}
			let off_time = moment(`00:00:00`, 'hh:mm:ss');
			if(values.off_time_moment){
				off_time =  moment(values.off_time_moment);
			}
			const res = await postAddIot({
				device_unit_info: {
					device_id: +id,
					name: values.name, //物联设备名称
					type: values.type, //物联设备类型
					port1: values.port1, //物联设备电源控制端口1
					port2: values.port2, //物联设备电源控制端口2
					panid: values.panid, //物联模块物联ID
					boot_time: boot_time.hours() * 3600 + boot_time.minutes() * 60 + boot_time.seconds(), //物联设备开机时间
					off_time: off_time.hours() * 3600 + off_time.minutes() * 60 + off_time.seconds(), //物联设备关机时间
					boot_sync: false, //物联设备开机同步
					off_sync: false, //物联设备关机同步
					send_code_times: +values.send_code_times, //物联设备发码次数
					send_code_interval: +values.send_code_interval, //物联设备发码间隔
					comm_type: values.comm_type, //物联设备通信控制方式
					comm_port: +values.comm_port, //物联设备通信控制端口
					brand_id: +values.brand_id, //物联设备品牌ID
					comm_address: values.comm_address //物联设备通信控制地址
				}
			});
			if (_.get(res, 'result.code') === 0) {
				message.success('添加成功');
				setModalType('');
				setIsModalVisible(false);
				addForm.resetFields();
			}
			fetchIotList();
		} catch (error) {
			console.log('error ', error);
		}
	};
	//修改物联设备
	const editIot = async () =>{
		try {
			const values = await addForm.validateFields();
			let boot_time = moment(`00:00:00`, 'hh:mm:ss');
			if(values.boot_time_moment){
				boot_time	=  moment(values.boot_time_moment);
			}
			let off_time = moment(`00:00:00`, 'hh:mm:ss');
			if(values.off_time_moment){
				off_time =  moment(values.off_time_moment);
			}
			const res = await postEditIot({
				device_id: +id,
				iot_device: {
					unit_id: +unitId,
					name: values.name, //物联设备名称
					panid: values.panid, //物联模块物联ID
					port1: values.port1, //物联设备电源控制端口1
					port2: values.port2, //物联设备电源控制端口2
					boot_time: boot_time.hours() * 3600 + boot_time.minutes() * 60 + boot_time.seconds(), //物联设备开机时间
					off_time: off_time.hours() * 3600 + off_time.minutes() * 60 + off_time.seconds(), //物联设备关机时间
					boot_sync: false, //物联设备开机同步
					off_sync: false, //物联设备关机同步
					correcting: values.correcting == true ? 1 : 0,
				}
			});
			if (_.get(res, 'result.code') === 0) {
				message.success('修改成功');
				setIsModalVisible(false);
				setModalType('');
				addForm.resetFields();
			}
			fetchIotList();
		} catch (error) {
			console.log('error ', error);
		}

	}

	/** 添加或删除物联设备 */
	const handleOk = () => {
		if(modalType == 'add'){
			addIot();
		}else if(modalType == 'edit'){
			editIot();
		}
	};

	/** 弹窗关闭 */
	const onModalCancel = () => {
		addForm.resetFields();
		setIsModalVisible(false);
	};
	useEffect(() => {
		fetchIotList();
		fetchIotOptions();
	}, []);

	const RenderHeader = () => {
		return (
			<header className="module-header">
				<div className="module-header-title module-header-iot">
					<div>
						物联设备
						{/* <div className="iot-eq-tip">提醒:物联模块使用前请先配对 , 点击查看模块与端口信息操作说明.</div> */}
					</div>
					<span>
						<IconFont type="icon-tianjia" className="icon" onClick={onAddModal} />
					</span>
				</div>
			</header>
		);
	};
	const RenderTable = () => {
		const li = {
			name: '投影仪',
			type: '照明',
			control: '大功率模块',
			port: '端口3',
			mode: '无',
			ip: '192.168.0.1',
			cport: '无',
			brand: '未知品牌',
			startupTimeLinkage: true,
			startupTime: 60,
			shutdownlinkage: false,
			shutdownTime: 60
		};
		const tableHeader = [
			{
				title: '被控设备名称',
				dataIndex: 'name',
				key: 'name',
				align: 'center',
				textWrap: 'word-break',
				ellipsis: true,
				width: 120
			},
			{
				title: '被控设备类型',
				dataIndex: 'type',
				key: 'type',
				width: 120,
				align: 'center',
				textWrap: 'word-break',
				render:(val)=>{
					if(iotOptions.iot_device_type){
						for(let i = 0; i < iotOptions.iot_device_type.length; i++){
							if(val === iotOptions.iot_device_type[i].id){
								return iotOptions.iot_device_type[i].name;
							}
						}	
					}				
				}
			},
			{
				title: '控制模块',
				dataIndex: 'module_name',
				key: 'module_name',
				align: 'center',
				width: 100,
				textWrap: 'word-break'
			},
			{
				title: '电源控制端口',
				dataIndex: 'port1',
				key: 'port1',
				width: 120,
				align: 'center',
				textWrap: 'word-break',
				render:(val)=>{
					if(iotOptions.power_output_port){
						for(let i = 0; i < iotOptions.power_output_port.length; i++){
							if(val === iotOptions.power_output_port[i].id){
								return iotOptions.power_output_port[i].name;
							}
						}	
					}				
				}
			},
			{
				title: '通讯方式',
				dataIndex: 'comm_type',
				key: 'comm_type',
				width: 100,
				align: 'center',
				textWrap: 'word-break',
				render:(val)=>{
					if(iotOptions.udfc_transmit_mode){
						for(let i = 0; i < iotOptions.udfc_transmit_mode.length; i++){
							if(val === iotOptions.udfc_transmit_mode[i].id){
								return iotOptions.udfc_transmit_mode[i].name;
							}
						}	
					}				
				}
			},
			// {
			// 	title: '通讯控制地址',
			// 	dataIndex: 'comm_address',
			// 	key: 'comm_address',
			// 	width: 120,
			// 	align: 'center',
			// 	textWrap: 'word-break'
			// },
			// {
			// 	title: '通讯控制端口',
			// 	dataIndex: 'comm_port',
			// 	key: 'comm_port',
			// 	align: 'center',
			// 	width: 120,
			// 	textWrap: 'word-break',
			// 	render:(val)=>{
			// 		if(iotOptions.comm_port){
			// 			for(let i = 0; i < iotOptions.comm_port.length; i++){
			// 				if(val === iotOptions.comm_port[i].id){
			// 					return iotOptions.comm_port[i].name;
			// 				}
			// 			}	
			// 		}				
			// 	}				
			// },
			{
				title: '品牌',
				dataIndex: 'brand_name',
				key: 'brand_name',
				align: 'center',
				width: 100,
				textWrap: 'word-break'
			},			
			{
				title: '操作',
				align: 'center',
				width: 100,
				textWrap: 'word-break',
				fixed: 'right',
				render: item => {
					return (
						<div className="cell-action-btns">
							<IconFont type="icon-bi" className="icon icon-edit" onClick={() => onEditModal(item)} />
							<IconFont type="icon-del" className="icon icon-del" onClick={() => onDelRow(item)} />
						</div>
					);
				}
			}
		];
		return (
			<div className="iot-table">
				<Table rowKey={item => item.id + '_'} scroll={{ x: '100%' }} dataSource={iotList} columns={tableHeader} pagination={{ defaultPageSize: 10 }}></Table>
			</div>
		);
	};

	const RenderModal = () => {
		const title = modalType === 'add' ? '添加物联设备' : '修改物联设备';
		let type = addForm.getFieldValue('type'), comm_type = addForm.getFieldValue('comm_type');
		return (
			<div>
				<Modal transitionName="" maskTransitionName="" forceRender={true} destroyOnClose preserve={false} title={title} cancelText="取消" okText="确定" visible={isModalVisible} onOk={handleOk} onCancel={() => onModalCancel(false)}>
					<Form colon={false} labelAlign="left" wrapperCol={{ span: 12, offset: 1 }} labelCol={{ span: 7 }} initialValues={addIotFormData} form={addForm}>
						<Form.Item
							label="被控设备名称"
							name="name"
							rules={[
								{
									required: true,
									message: '请填写设备名称'
								}
							]}
						>
							<Input placeholder="请输入被控设备名称"  />
						</Form.Item>
						{
							(type === 8 || type == 5) &&
							<Form.Item
								label="电机校正"
								name="correcting"
								valuePropName='checked'
								rules={[
									{
										required: true,
										message: '请填写电机校正'
									}
								]}
							>
								<Switch  checkedChildren="开" unCheckedChildren="关"  />
							</Form.Item>
						}	
						<Form.Item
							label="被控设备类型"
							name="type"
							rules={[
								{
									required: true,
									message: '请选择设备类型'
								}
							]}
						>
							<Select placeholder="请选择被控设备类型" onChange={chooseType} disabled={modalType !== 'add'}>
								{_.map(iotOptions.iot_device_type, (item, key) => {
									return (
										<Select.Option value={item.id} key={key + '_' + item.id + 'iot_device_type'}>
											{item.name}
										</Select.Option>
									);
								})}
							</Select>
						</Form.Item>
						{
							(type === 11 || type === 12) &&
							<Form.Item
								label="电量统计设备类型"
								name="part1"
								rules={[
									{
										required: true,
										message: '请选择设备类型'
									}
								]}
								>
								<Select placeholder="请选择被控设备类型" disabled={modalType !== 'add'}>
									{_.map(iotOptions.electricity_types, (item, key) => {
										return (
											<Select.Option value={item.id} key={key + '_' + item.id + 'electricity_types'}>
												{item.name}
											</Select.Option>
											);
										})}
								</Select>
							</Form.Item>
						}
						<Form.Item label="控制模块" name="panid" 
						rules={[
								{
										required: true,
										message: '请选择控制模块'
								}
								]}>
							<Select
								placeholder="请选择控制模块"
								disabled={modalType !== 'add'}
							>
								{_.map(iotOptions.iot_module, (item, key) => {
									return (
										<Select.Option value={item.id} key={key + '_' + item.id + 'port2'}>
											{item.name}
										</Select.Option>
									);
								})}
							</Select>
						</Form.Item>

						<Form.Item
							label="电源输出控制端口"
							name="port1"
							rules={[
								{
									required: true,
									message: '请选择电源输出控制端口'
								}
							]}
						>
							<Select placeholder="请选择电源输出控制端口" disabled={modalType !== 'add'}>
								{_.map(iotOptions.power_output_port, (item, key) => {
									return (
										<Select.Option value={item.id} key={key + '_' + item.id + 'port1'}>
											{item.name}
										</Select.Option>
									);
								})}
							</Select>
						</Form.Item>
						{/* {							
						type === 10 &&
						<Form.Item label="通信控制方式" name="comm_type" 		
						rules={[
							{
								required: true,
								message: '请选择通信控制方式'
							}
						]}>
							<Select
								placeholder="请选择通信控制方式" disabled={modalType !== 'add'}
						
							>
								{_.map(iotOptions.udfc_transmit_mode, (item, key) => {
									return (
										<Select.Option value={item.id} key={key + '_' + item.id + 'udfc_transmit_mode'}>
											{item.name}
										</Select.Option>
									);
								})}
							</Select>
						</Form.Item>
						} */}
						{
							type === 9
							||(type === 10 && (comm_type === 3 || 
								comm_type === 4))
							&&
							<Form.Item
								label="通信控制地址"
								name="comm_address"
								rules={[
									{
										required: true,
										message: '请填写通信控制地址'
									}
								]}
							>
								<Input placeholder="请填写通信控制地址" disabled={modalType !== 'add'}/>
							</Form.Item>
						}
						{
							(
								type === 6
							)
							&&
								<Form.Item label="通信控制端口" name="comm_port"
								rules={[
										{
											required: true,
											message: '请选择通信控制端口'
										}
									]}>
								<Select
									placeholder="请选择通信控制端口" disabled={modalType !== 'add'}
									
								>
									{_.map(iotOptions.comm_port, (item, key) => {
										return (
											<Select.Option value={item.id} key={key + '_' + item.id + 'udfc_transmit_mode'}>
												{item.name}
											</Select.Option>
										);
									})}
								</Select>
							</Form.Item>
						}
						{
							
							(type === 2||
								type === 3||
								type === 6
							)&&
							<Form.Item label="被控设备品牌" name="brand_id"
								rules={[
									{
										required: true,
										message: '请选择被控设备品牌'
									}
								]}
							>
								<Select placeholder="请选择物联设备品牌" disabled={modalType !== 'add'}>
									{_.map(brandList, (item, key) => {
										return (
											<Select.Option value={item.id} key={key + '_' + item.id + 'brand_id'}>
												{item.brand}
											</Select.Option>
										);
									})}
								</Select>
							</Form.Item>

						}
						{/* <Form.Item
							label="电源控制端口2"
							name="port2"
							rules={[
								{
									required: true,
									message: '请选择电源控制端口2'
								}
							]}
						>
							<Select placeholder="请选择电源控制端口2">
								{_.map(iotOptions.power_output_port, (item, key) => {
									return (
										<Select.Option value={item.id} key={key + '_' + item.id + 'port2'}>
											{item.name}
										</Select.Option>
									);
								})}
							</Select>
						</Form.Item> */}
						{/* {							
						<Form.Item label="开机联动" name="boot_sync" valuePropName="checked">
							<Checkbox />
						</Form.Item>
						}
						{
							(type === 2||
								type === 3||
								type === 5||
								type === 6||
								type === 7||
								type === 8||
								type === 9||
								type === 10
							)&&
							<Form.Item
								label="开机时间"
								name="boot_time_moment"
								rules={[
									{
										required: true,
										message: '请选择开机时间'
									}
								]}
							>
								<TimePicker locale={locale} />
							</Form.Item>
						}
						{	
						<Form.Item label="关机联动" name="off_sync" valuePropName="checked">
							<Checkbox />
						</Form.Item>
						}
						{(type === 2||
								type === 3||
								type === 5||
								type === 6||
								type === 7||
								type === 8||
								type === 9||
								type === 10
							)&&
						<Form.Item
							label="关机时间"
							name="off_time_moment"
							rules={[
								{
									required: true,
									message: '请选择关机时间'
								}
							]}
						>
							<TimePicker locale={locale} />
						</Form.Item>
						} */}
						{/* <Form.Item
							label="物联设备发码次数"
							name="send_code_times"
							rules={[
								{
									required: true,
									message: '请填写发码次数'
								}
							]}
						>
							<Input placeholder="请输入发码次数" />
						</Form.Item>
						<Form.Item
							label="物联设备发码间隔"
							name="send_code_interval"
							rules={[
								{
									required: true,
									message: '请填写发码间隔'
								}
							]}
						>
							<Input placeholder="请输入发码间隔" />
						</Form.Item>					 */}
					</Form>
				</Modal>
			</div>
		);
	};
	return (
		<section className="AdvancedSetting">
			<RenderHeader />
			<RenderTable />
			<RenderModal />
		</section>
	);
};

export default IotModule;
