import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts';
import BottomRightTable from './BottomRightTable';
import { Col, Row} from 'antd';
import _ from 'lodash';
let myChart = null;

const ContrastBar = (props) => {
	const [iotDeviceInfo, setIotDeviceInfo] = useState({});
	const {data} = props;
	let yAxis = [];
	let series = [];
	data && data.type && data.type.forEach((item)=>{
		series.push({
			name: item,
			type: 'bar',
			data: []
		});
	});
	data && data.data.forEach((item)=>{
		yAxis.push(item.name);
		if(item.data){
			for(let i = 0; i < item.data.length; i++){
				if(series.length > i){
					series[i].data.push(item.data[i]);
				}
			}
		}
	});	
	const refreshSerial = (seriesIndex)=>{
		if(data?.list?.length > seriesIndex){
			setIotDeviceInfo(data.list[seriesIndex]);
		}else{
			setIotDeviceInfo(null)
		}
	}

	//refreshSerial(0);

	const renderChart = () => {
		var chartDom = document.getElementById('contrastBar');
		myChart = echarts.init(
			chartDom,
			{},
			{
				devicePixelRatio: 1,
				width: chartDom.clientWidth
			}
		);
		var option = {
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'shadow'
				}
			},
			color: ['#649DA6', '#58455B', '#E96036', '#6F9087', '#284C70', '#C2253A'],
			legend: {
				data: data.type,
				bottom: 0,
				itemWidth: 10,
				itemHeight: 10,
				icon: 'circle'
			},
			grid: {
				left: '0%',
				right: 45,
				bottom: 50,
				top: '50',
				containLabel: true
			},
			xAxis: {
				type: 'value',
				name: '台',
				axisLine: {
					show: true
				},
				axisTick: {
					show: true
				}
			},
			yAxis: {
				type: 'category',
				data: yAxis
			},
			series: series
		};

		option && myChart.setOption(option);
		myChart.on('click', (params)=>{
			let seriesIndex = params.seriesIndex;
			refreshSerial(seriesIndex);	
		});
	};


	const watchWindowSize = () => {
		_.delay(() => {
			myChart && myChart.resize({
				width: document.getElementById('contrastBar') && document.getElementById('contrastBar').clientWidth
			});
		}, 300);
	};
	useEffect(() => {
		setTimeout(() => {
			renderChart();
			refreshSerial(0);
		}, 50);
	}, []);
	useEffect(() => {
		window.addEventListener('resize', watchWindowSize, false);
		return () => window.removeEventListener('size', watchWindowSize, false);
	});
	return (
		<Row gutter={30} className='tow-charts'>
			<Col span="12">
		<section className="contrastBar-wrapper">
			<div id="contrastBar" className="contrastBar"></div>						
		</section>
		 </Col>
		<Col span="12">
			{
				iotDeviceInfo && <BottomRightTable data={iotDeviceInfo}/>
			}
		</Col>
		</Row>
	);
};

export default ContrastBar;
