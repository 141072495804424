import React, { Component } from 'react';
import { Modal, Form, Input, Button, Transfer, Select, message, } from 'antd';
import { setAddUserMessage, setRoleDepartment, getUserDetails, postValidatePhone } from '@/server/system/user'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { getScenePlace } from '@/server/scene'
import IconFont from '@/components/IconFont';
import md5 from 'blueimp-md5'
import _ from 'lodash'
import '../index.scss'
import { SwitchTransition } from 'react-transition-group';


class AddUserModal extends Component {
  formRef = React.createRef();
  callback = null;

  constructor(props) {
    super(props);
    this.resetState();
  }

  resetState(){
    this.state = {
      userId: null,
      isModalVisible: false,
      steps: 1,
      placeList: [],
      targetKeys: [],
      selectedKeys: [],
      user_department: [],
      user_roles: [],
      userMess: {},
      passwordChange: false
    }
  }

  resetStates(cb){
    this.setState({
      userId: null,
      isModalVisible: false,
      steps: 1,
      placeList: [],
      targetKeys: [],
      selectedKeys: [],
      user_department: [],
      user_roles: [],
      userMess: {}
    },()=>{
      cb && cb();
    })
  }

  componentDidMount() {
		// this.getplaceList()
	}

  // 角色与部门获取接口
  setRoleDepartmentFun = async() => {
    const { user_department, user_roles} = await setRoleDepartment()
    this.setState({
      user_department,
      user_roles
    })

  }

  // 获取用户信息
  userDetails = async(id, info) => {
      this.setState({
        userId: id,
        userMess: info
      }, ()=>{
        const userMess = this.state.userMess;
        console.log(this.formRef.current);
        this.formRef.current.setFieldsValue({
          'userName': userMess.userName,
          'name' : userMess.name,
          'phonenum': userMess.phone,
          'usertype': userMess.type,
          'department': userMess.department,
          'password': '123456'
        })
      });
      const data = await getUserDetails({
        "user_info": {
            "id": id
        }
      })
      let targetKeys = []
      if (_.get(data, 'result.code') === 0) {
        const placelist = _.get(data, 'place_list');
        placelist &&  placelist.map(item => {
          item.room.map(flag => {
            targetKeys.push(flag.id)
          })
        })
      }
      this.setState({
        targetKeys: targetKeys
      });
  }
  
  onPasswordChange(){
    this.setState({
      passwordChange: true
    })
  }

  onChange = (nextTargetKeys, direction, moveKeys) => {
    console.log('targetKeys:', nextTargetKeys);
    console.log('direction:', direction);
    console.log('moveKeys:', moveKeys);
    this.setState({
      targetKeys: nextTargetKeys
    })
  }

   onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    console.log('sourceSelectedKeys:', sourceSelectedKeys);
    console.log('targetSelectedKeys:', targetSelectedKeys);
    this.setState({
      selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys]
    })
  };

  onScroll = (direction, e) => {
    //console.log('direction:', direction);
    //console.log('target:', e.target);
  };

  // 添加用户弹窗
  showModal = (callback) => {
    this.callback = callback;
    this.setState({
        userId: null,
        isModalVisible: true,
        steps: 1,
        targetKeys: [],
        passwordChange: false
      }, () => {
        this.setRoleDepartmentFun()
      })
  }

  handleOk = () => {
    this.setState({
        isModalVisible: false
    },()=>{
      if(this.callback){
        this.callback();
      }
    })
  }

  // 下一步
  nextMessage = async() => {
    if (this.state.steps === 1) {
      try {
          const values = await this.formRef.current.validateFields();
          //首先验证手机号是否重复

          let content = {
            "user_info": {
                "phone": String(values.phonenum)
            }
          }
          if(this.state.userId){
              content.user_info.userId = this.state.userId
          }
          const data = await postValidatePhone(content)
          if (_.get(data, 'result.code') !== 0) {
              return
          }

          this.setState({
            steps: 2,
            userMess: values
          }, () => {
            this.setRoleAddEditFun()
          });
      }catch(errorInfo){
        console.log('Failed:', errorInfo);
      }
    } else {
      this.setAddOrEditUserMessageFun()
    }
    
  }

  // 添加用户信息
  setAddOrEditUserMessageFun = async () => {
    let body = {
        "user_info": {
          "name": this.state.userMess.userName,
          "role": this.state.userMess.usertype,
          "xingming": this.state.userMess.name,
          "department": this.state.userMess.department,
          "phone": `${this.state.userMess.phonenum}`,
          "place_id": this.state.targetKeys
      }
    }
    //增加或者密码被修改的时候，加上密码
    if(this.state.passwordChange || this.state.userId == null){
      body.user_info.password = md5(this.state.userMess.password);
    }
    if(!this.state.passwordChange){
      body.user_info.password = "";
    }
    if(this.state.userId){
      body.user_info.id = this.state.userId
    }

    const data = await setAddUserMessage(body)
    if (_.get(data, 'result.code') === 0) {
      message.success('操作成功')
      this.setState({
        steps: 3
      },() => {
        //this.props.refresh()
      })
    }
  }

  // 上一步
  previous = () => {
    this.setState({
      steps: 1
    })
  }

  // 查询设备位置
  setRoleAddEditFun = async() => {
    const { placelist } = await getScenePlace({
      "search": {
        "keyword": ""
    }
    })
    let arr = []
    placelist &&  placelist.map(item => {
      item.room.map(flag => {
        arr.push({
          key: flag.id,
          title: flag.name,
          description: flag.name
        })
      })
    })
    this.setState({
      placeList: arr
    })
  }

  handleCancel = () => {
    this.setState({
      isModalVisible: false
    })
  }

  render() {
    return (
      <div className="add-user">
        {/* 添加用户  begin*/}
        {this.state.isModalVisible ? <Modal title="用户添加"  footer={null} visible={this.state.isModalVisible} onOk={() => this.handleOk()} onCancel={() => this.handleCancel()}>
         {/* 进度条 begin */}
         <div className="add-user-Modal-herder">
           <div className={this.state.steps === 1 ? 'zong cur one' : 'zong one'} >
           <div className="user">
            <div className="user-text">1</div>
            <div className="user-border"></div>
           </div>
           <div className="add-user-title">用户信息</div>
           </div>
           <div className={this.state.steps === 2 ? 'zong cur two' : 'zong two'}>
           <div className="user">
           <div className="user-border"></div>
            <div className="user-text">2</div>
            <div className="user-border"></div>
           </div>
           <div className="add-user-title">设备授权</div>
           </div>
           <div className={this.state.steps === 3 ? 'zong cur three' : 'zong three'}>
           <div className="user">
           <div className="user-border"></div>
           <div className="user-text">3</div>
           </div>
           <div className="add-user-title">完成</div>
           </div>
         </div>
         {/* 进度条end */}
         {/* 内容区域 begin */}
         <div className="content-main-user">
           {/* 步骤一 begin */}
           <div className={this.state.steps === 1 ? 'block steps-one' : 'none'}>
           <Form layout="inline" ref={this.formRef} 
           className="searh-header-form">
             <Form.Item
                label="用户名"
                rules={[{ required: true, message: '请输入用户名！' }]}
                name='userName'
              >
                <Input autoComplete="new-password" style={{ width: '206px' }} placeholder='请输入用户名'/>
              </Form.Item>
              <Form.Item
                label="密码"
                rules={[{ required: true, message: '请输入密码！' }]}
                name='password'             
              >
                <Input.Password  autoComplete="new-password" style={{ width: '206px' }} placeholder='请输入密码' allowClear visibilityToggle={false} onChange={this.onPasswordChange.bind(this)}/>
              </Form.Item>
              <Form.Item
                label="姓名"
                rules={[{ required: true, message: '请输入姓名！' }]}
                name='name'
              >
                <Input style={{ width: '206px' }} placeholder='请输入姓名'/>
              </Form.Item>
              <Form.Item
                label="手机号"
                rules={[{ required: true, pattern:/^1[0-9]{10}$/, message:'请输入正确的手机号'}]}
                name='phonenum'
              >
                <Input style={{ width: '206px' }} placeholder='请输入手机号'/>
              </Form.Item>
              <Form.Item
                name='department'
                rules={[{ required: true, message: '请选择部门名称！' }]}
                label="部门"
              >
                <Select placeholder='请选择部门名称' style={{ width: '206px' }}>
                  {
                    this.state.user_department.map((item,index) => {
                      return (
                        <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                      )
                    })
                  }
                </Select>
              </Form.Item>
              <Form.Item
                name='usertype'
                rules={[{ required: true, message: '请选择用户类型！' }]}
                label="用户类型"
              >
                <Select placeholder='请选择用户类型' style={{ width: '206px' }}>
                {
                    this.state.user_roles.map((item,index) => {
                      return (
                        <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                      )
                    })
                  }
                </Select>
              </Form.Item>
           </Form>
           </div>
           {/* 步骤一 end */}
           {/* 步骤二 begin */}
           <div className={this.state.steps === 2 ? 'block steps-two' : 'none'}>
           <Transfer
              dataSource={this.state.placeList}
              titles={['未选位置', '已选位置']}
              locale ={{itemUnit: '项', itemsUnit: '项'}}
              targetKeys={this.state.targetKeys}
              selectedKeys={this.state.selectedKeys}
              onChange={this.onChange}
              onSelectChange={this.onSelectChange}
              onScroll={this.onScroll}
              render={item => item.title}
            />
           </div>
           {/* 步骤二 end */}
           {/* 步骤三 begin */}
           <div className={this.state.steps === 3 ? 'block steps-three' : 'none'}>
             <IconFont type="icon-chenggong" />
             <div className="suss-text">{this.state.userId == null ? '添加完成' : '修改完成'}</div>
           </div>
           {/* 步骤三 end */}
         </div>
         {/* 按钮 begin */}
          <div className="footer">
          {this.state.steps === 2 ? (<Button type="primary" onClick={() => this.previous()}>上一步</Button>) : '' }
          {this.state.steps === 1 || this.state.steps === 2 ? (<Button type="primary" onClick={() => this.nextMessage()}>下一步</Button>) : '' }
          {this.state.steps === 3 ? (<Button type="primary" onClick={() => this.handleOk()}>关闭</Button>) : '' }
          </div>
         {/* 按钮 end */}
         {/* 内容区域 end */}
        </Modal>
        : ''}
        
      </div>
    );
  }
}

export default AddUserModal;
