import React, {useEffect} from 'react';
import { Button, Select, Form, Input, Radio, Modal, TreeSelect,Checkbox,Switch,InputNumber,message } from 'antd';
import { useState } from 'react';
import { getQuery } from '../../../../utils';
import { getDeviceAdvancedSetting, setDeviceAdvancedSetting} from '@/server/device';
import { getPositionSchoolList, getPositionBinded, bindPosition, unbindPosition} from '@/server/system/partition';
import _ from 'lodash';

const AdvancedSetting = (prop) => {
const [setting, setSetting] = useState({});
const [placeList, setPlaceList] = useState({});
const [placeBinded, setPlaceBinded] = useState({});
const [placeBindedId, setPlaceBindedId] = useState({});
const [form] = Form.useForm();
const [bindForm] = Form.useForm();
const RenderHeader = () => {
	const [isBinding, setBinding] = useState(false);
	return (
		<Form layout="horizontal" form={bindForm} className="searh-header-form">
		<header className="module-header">
			<div className="module-header-title">设备位置</div>
			<div className="module-header-content">
				<div className="left disabled">{placeBinded.length > 0? placeBinded :'未绑定'}</div>
				<div className="right">
					{ placeBinded.length ==0 && !isBinding && (
						<Button type="primary" className="mini-button" onClick={() => setBinding(true)}>
							绑定
						</Button>
					)}
					{
						placeBinded.length > 0 && (
							<Button type="primary" className="mini-button" onClick={() => onUnbindClick()}>
								解绑
							</Button>
						)
					}
					{isBinding && (
						<div className="bind-box">
							<Form.Item
									name="place"
									rules={[
										{
											required: true,
											message: '请选择位置'
										}
									]}
							>
							<TreeSelect
							style={{ width: '100%' }}
							dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
							treeData={placeList}
							placeholder="请选择位置"
							treeDefaultExpandAll
						/>
							</Form.Item>
							<Button type="primary" className="mini-button" onClick={()=>bindPositionData()}>
								确认
							</Button>
							<Button className="mini-button cancel" onClick={() => setBinding(false)}>
								取消
							</Button>
						</div>
					)}
				</div>
			</div>
		</header>
		</Form>
	);
};

const onUnbindClick = async()=>{
	Modal.confirm({
		title: '提示',
		content: `确认要解绑教室 "${placeBinded}"？`,
		okText: '确认',
		cancelText: '取消',
		async onOk() {			
			const id = getQuery('id');
			const res = await unbindPosition({ 
				place_id: placeBindedId,
				device_id: +id
			});
			if (_.get(res, 'result.code') == 0) {
				getPositionBindedData();
				prop.onRefreshBindedPlace && prop.onRefreshBindedPlace();
			}
		}
	});
	
}

const getSettingData = async () => {
	const id = getQuery('id');
	const res = await getDeviceAdvancedSetting({ device_id: +id });
	if (_.get(res, 'result.code') == 0) {
		let advenvecd_parameter = _.get(res, 'advanced_parameter', {});
		advenvecd_parameter.check_group = [];
		if(advenvecd_parameter.card_swiped_open_device && advenvecd_parameter.card_swiped_open_device == true){
			advenvecd_parameter.check_group.push('card_swiped_open_device');
		}
		if(advenvecd_parameter.card_swiped_unlock_panel != null && advenvecd_parameter.card_swiped_unlock_panel == true){
			advenvecd_parameter.check_group.push('card_swiped_unlock_panel');
		}
		setSetting(advenvecd_parameter);
	}
};

const getPositionSchoolListData = async () => {
	const res = await getPositionSchoolList({ place: {
			level: 3,  
			"parent_id": 0 
		}});
	if (_.get(res, 'result.code') == 0) {
		const placeList = _.get(res, 'placelist', {});
		//转化为树状结构
		if(placeList){
			let placeData = [];
			for(let i = 0; i < placeList.length; i++){
				const place = placeList[i];
				let data = {
					title: place.name,
					value: place.id,
					selectable: false
				}
				if(place.room && place.room.length > 0){
					data.children = []
					for(let j = 0; j < place.room.length; j++){
						data.children.push({
							title: place.room[j].name,
							value: place.room[j].id
						});
					}
				}
				placeData.push(data);
			}

			setPlaceList(placeData);
		}else{
			setPlaceList([]);
		}
	}
};

const getPositionBindedData = async () => {
	const id = getQuery('id');
	const res = await getPositionBinded({ place: {
			device_id: +id
		}});
	setPlaceBinded('');
	if (_.get(res, 'result.code') == 0) {
		const id = _.get(res, 'id');
		if(id != null && id != 0){
			setPlaceBinded(_.get(res, 'place'));
			setPlaceBindedId(id);
		}
	}
};

const bindPositionData = async () => {
	try {
	const values = await bindForm.validateFields();
	const id = getQuery('id');
	const res = await bindPosition({ 
			place_id: values.place,
			device_id: +id
		});
	if (_.get(res, 'result.code') == 0) {
		getPositionBindedData();
		prop.onRefreshBindedPlace && prop.onRefreshBindedPlace();
	}}
	catch (error) {
		console.log('error ', error);
	}
};


useEffect(() => {
	getSettingData();
	getPositionSchoolListData();
	getPositionBindedData();
}, []);

const RenderForm = () => {
	const [radio, setRadio] = useState(1);
	const onChange = e => {
		console.log('radio checked', e.target.value);
		setRadio(e.target.value);
	};
	return (
		<div className="form-wrap">
			<Form  form={form}  labelAlign="left"  initialValues={setting}>
				<Form.Item label="磁控锁开锁延迟(S)"
					name="magnetic_lock_open_time"
					rules={[
						{
							required: true,
							message: '请输入有效数字',
							pattern: /^[0-9]+$/
						},
						{
								required: true,
								message: '请输入磁控锁开锁延迟',
						}
					]}>
					<InputNumber min={1}  max={5000}   placeholder="请输入磁控锁开锁延迟(S)"></InputNumber >
				</Form.Item>
				<Form.Item label="磁控锁开锁电平"
					name="magnetic_lock_open_level"
					rules={[
						{
							required: true,
							message: '请输入磁控锁开锁电平'
						}
					]}>
					<Radio.Group value={radio} onChange={onChange}>
						<Radio value={1}>高电平</Radio>
						<Radio value={2}>低电平</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item label="LCD待机息屏延迟(S)"
					name="lcd_panel_off_time"
					rules={[
						{
							required: true,
							message: '请输入有效数字',
							pattern: /^[0-9]+$/
						},
						{
							required: true,
							message: '请输入LCD待机息屏延迟'
						}
					]}>
					<InputNumber  min={1}  max={5000}   placeholder="请输LCD待机息延迟(S)"></InputNumber >
				</Form.Item>
				{/* <Form.Item label="刷卡联动" name='check_group'>
					<Checkbox.Group>		
						<Checkbox value="card_swiped_unlock_panel">解锁面板</Checkbox>		
						<Checkbox value="card_swiped_open_device">开机</Checkbox>	
					</Checkbox.Group>	
				</Form.Item> */}
				<Form.Item label="待机自动锁定"  name="panel_standby_lock"  valuePropName="checked">
					<Switch />
				</Form.Item>
				{/* <Form.Item label="待机自动锁定延迟" name='panel_standby_lock_time'>
						<InputNumber  min={1}  max={5000} placeholder="请输待机自动锁定延迟(S)"></InputNumber >
					</Form.Item>
				<Form.Item label="中控通讯接口2通信方式" name = "device_com2_type">		
					<Radio.Group value={radio} onChange={onChange}>
							<Radio value={0}>RS232</Radio>
							<Radio value={1}>RS485</Radio>
					</Radio.Group>
				</Form.Item> */}
			<div className="submit-button-box">
			<Button className='submit-button' type='primary' onClick={onSunbmit}>保存</Button>
			</div>
			</Form>
		</div>
		);
	};

	/** 提交表单 */
	const onSunbmit = async () => {
			try {
				const id = getQuery('id');
				let values = await form.validateFields();
				console.log('Success:', values);
				//check group 需要做一下转换
				let check_group = values.check_group;
				values.card_swiped_unlock_panel = false;
				values.card_swiped_open_device = false;
				values.panel_standby_lock_time = 10;
				values.device_com2_type = 1;
				if(check_group){
					for(let i = 0; i < check_group.length; i++){
						if(check_group[i] === 'card_swiped_unlock_panel'){
							values.card_swiped_unlock_panel = true;
						}
						else if(check_group[i] === 'card_swiped_open_device'){
							values.card_swiped_open_device = true;
						}
					}
				}
				const params = {
					device_id: +id,
					advanced_parameter: values
				};
				const res = await  setDeviceAdvancedSetting(params);
				if (_.get(res, 'result.code') === 0) {
					message.success('操作成功');
				}
			} catch (errorInfo) {
				console.log('Failed:', errorInfo);
			}
	};

	return (
		<section className="AdvancedSetting">
			<RenderHeader />
			<RenderForm />
		</section>
	);
};

export default AdvancedSetting;
