import React,{Component} from 'react';
import {Table} from 'antd';

class TopLeftTable extends Component{
	state = {
		types: []
	};
	setData = (data)=>{		
		this.setState({
			types: data
		});
	}
  tableHeader = [
		{
			title: '状态',
			dataIndex: 'name',
     		 key: 'name',
		},  
			{
				title: '数量',
				dataIndex: 'number',
				key: 'number',
		},
			{
				title: '占比',
				dataIndex: 'ratio',
				key: 'ratio',
			},            
  ];
  render(){
	return (
	  <section className="top-left-table">
		<header className="table-title">中控状态概况</header>
		<Table dataSource={this.state.types} columns={this.tableHeader} pagination={false}/>
	  </section>
	)
  };
}

export default TopLeftTable;