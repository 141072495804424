import * as actionTypes from '../constants/index';
const logo = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.SET_LOGO:
			return action.data;
		default:
			return state;
	}
};

export default logo;


