import { get, post } from '@/axios';

// 分区列表获取
export const getPartitionList = (query) => {
  return get('/app/place?cmd=10' , query)
}

// 分区修改
export const getPartitionEdit = (query) => {
  return post('/app/place?cmd=3' , query)
}

// 分区添加
export const getPartitionAdd = (query) => {
  return post('/app/place?cmd=2' , query)
}

// 分区删除
export const getPartitionDel = (query) => {
  return post('/app/place?cmd=4' , query)
}
// 获取学校分区
export const getPositionSchoolList = (query) => {
  return post('/app/place?cmd=19' , query)
}
// 获取绑定的学校
export const getPositionBinded = (query) => {
  return post('/app/place?cmd=6' , query)
}
// 绑定位置
export const bindPosition = (query) => {
  return post('/app/place?cmd=20' , query)
}
// 解绑位置
export const unbindPosition = (query) => {
  return post('/app/place?cmd=21' , query)
}